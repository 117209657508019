import Game from "../Components/game/game"
import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
import Pagination from "../Components/pagination/pagination"
import { useState, useEffect, useRef, useContext } from "react"
import { getAll, getOne } from "../api"
import { toast } from "react-toastify"
import FilterGameMenu from "../Components/search/filter-game-menu"
import LoginPopup from '../Components/popup/login-popup'
import UserContext from "../Context/UserContext"
import { reviewKeys, reviewKeysName } from "../helpers/utility";
import RangeSlider from 'react-range-slider-input';

const ratingValues = {
  'gameplay': [1, 10],
  'story': [1, 10],
  'visuals': [1, 10],
  'sound': [1, 10],
  'controls': [1, 10],
  'replayability': [1, 10],
  'originality': [1, 10],
  'value': [1, 10],
  'technicalPerformance': [1, 10],
  'accessibility': [1, 10],
  'difficultyLevel': [1, 10],
  'hourlyPlayed': [1, 10]
}


const Plus = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M7.99968 5.33301V10.6663M5.33301 7.99968H10.6663M14.6663 7.99968C14.6663 11.6816 11.6816 14.6663 7.99968 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99968C1.33301 4.31778 4.31778 1.33301 7.99968 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99968Z" stroke="white" stroke-opacity="0.7" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
</svg>


const GamePage = ({ type, title }) => {
  const [user, setUser] = useContext(UserContext)
  const ITEMS_PER_PAGE = 10;

  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    if (!keyword) {
      getData();
    } else {
      paginationData(currentPage)
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, type]);




  const [filter, setFilter] = useState("Ratings")
  const min = 1
  const max = 10

  const [tools, setTools] = useState({
    genre: [],
    brand: [],
    platform: [],
    store: [],
  })
  useEffect(() => {
    getAll('/games/tools')
      .then(({ data }) => {
        setTools(data.data)
      })
  }, []);



  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/games/all', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: type
      });
      // if (category.length === 0) {
      //   const { data } = await getAll('/games/category/all');

      //   if (data.success) {
      //     setCategory(data.data.category)
      //   }
      // }


      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const paginationData = async (currentPage) => {
    setCurrentPage(currentPage)
    setIsLoading(true);
    try {
      const res = await getAll('/admin/search', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: "news",
        keyword: keyword
      });
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  const pagination = (item) => {
    setCurrentPage(item)
  }


  const filterSearch = (filterValue) => {
    setFilterIsLoading(true)
    getAll('/games/search', filterValue)
      .then(({ data }) => {

        if (data.success) {
          setData(data.data)
          setTotalPages(data.totalPages);
        }
        setFilterIsLoading(false)

      })
  }


  const favourite = (data) => {
    setData(data)
  }



  const [popup, setPopup] = useState(false)


  const popupcloseHandler = (isOpen, data, review) => {
    setPopup(false)


  }



  // Below functions for search filter 


  const [filtemenu, setFiltermenu] = useState(false)
  const filterMenuHandler = () => {
    setFiltermenu(!filtemenu)
  }


  const filterChangeHandler = (item) => {
    setFilter(item)
  }


  const [filterRating, setFilterRating] = useState(ratingValues)
  const ratingSliderInput = (price, key) => {
    setFilterRating({ ...filterRating, [key]: price })
  }

  const ratingInputHandler = (e, key, index) => {
    let copyRating = { ...filterRating }
    copyRating[key][index] = parseInt(e.target.value)
    setFilterRating(copyRating)
  }

  const [genre, setGenre] = useState({})
  const genreHandler = (e, key, index) => {

    let copyGenre = { ...genre }
    if (copyGenre[e.target.name]) {
      delete copyGenre[e.target.name]
    } else {
      copyGenre[e.target.name] = true
    }
    setGenre(copyGenre)

  }

  const [platform, setPlatform] = useState({})
  const platformHandler = (e, key, index) => {
    let copyPlatform = { ...platform }
    if (copyPlatform[e.target.name]) {
      delete copyPlatform[e.target.name]
    } else {
      copyPlatform[e.target.name] = true
    }
    setPlatform(copyPlatform)
  }

  const [store, setStore] = useState({})

  const storeHandler = (e, key, index) => {
    let copyStore = { ...store }
    if (copyStore[e.target.name]) {
      delete copyStore[e.target.name]
    } else {
      copyStore[e.target.name] = true
    }
    setStore(copyStore)
  }

  const [keyword, setKeyword] = useState('')

  const clearFilter = () => {
    if (filter === 'Ratings') setFilterRating(ratingValues)
    if (filter === 'Genres') setGenre({})
    if (filter === 'Platforms') setPlatform({})

  }


  const [data, setData] = useState([])
  const [notfound, setNotfound] = useState(false)
  const [filterIsLoading, setFilterIsLoading] = useState(false);

  const applyFilter = () => {
    setFilterIsLoading(true)
    setFiltermenu(false)

    let searchData

    if (filter == 'Ratings') searchData = filterRating
    if (filter == 'Genres') searchData = genre
    if (filter == 'Platforms') searchData = platform
    if (filter == 'Stores') searchData = store


    getAll('/games/search', { value: searchData, type: filter, keyword: keyword.trim() })
      .then(({ data }) => {

        if (data.success) {
          setData(data.data)
          if (data.data.length === 0) setNotfound(true)
        }

        setFilterIsLoading(false)

      })
  }

  const gameFinder = () => {
    const search = keyword.trim()
    if (search) {
      setFilterIsLoading(true)
      getAll('/games/search', { keyword: search })
        .then(({ data }) => {

          if (data.success) {
            setData(data.data)
            setTotalPages(data.totalPages)
            if (data.data.length === 0) setNotfound(true)
          }
          setFilterIsLoading(false)
        })
    }
  }




  return (
    <>
      <Header />
      {user.loginPopup &&
        <LoginPopup close={popupcloseHandler} gameData={data} />
      }

      {
        isLoading ?
          <div className="loader"></div>
          :

          <>

            <div className="container game_container">

              <div className="reviews_box flex">
                <div className="left">
                  <div className="reviews_heading">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                      <path d="M10.5 12.75H18M10.5 18H22.5M14.5256 27H24.3C26.8202 27 28.0804 27 29.043 26.5095C29.8897 26.0781 30.5781 25.3897 31.0095 24.543C31.5 23.5804 31.5 22.3202 31.5 19.8V11.7C31.5 9.17976 31.5 7.91965 31.0095 6.95704C30.5781 6.11031 29.8897 5.4219 29.043 4.99047C28.0804 4.5 26.8202 4.5 24.3 4.5H11.7C9.17976 4.5 7.91965 4.5 6.95704 4.99047C6.11031 5.4219 5.4219 6.11031 4.99047 6.95704C4.5 7.91965 4.5 9.17976 4.5 11.7V30.5033C4.5 31.3025 4.5 31.7022 4.66384 31.9074C4.80633 32.0859 5.0224 32.1898 5.25081 32.1895C5.51344 32.1892 5.82551 31.9396 6.44963 31.4403L10.0278 28.5777C10.7588 27.993 11.1243 27.7006 11.5312 27.4927C11.8923 27.3082 12.2766 27.1734 12.6738 27.0919C13.1215 27 13.5895 27 14.5256 27Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{title}</span>
                  </div>
                </div>

                <div className="right flex">

                  <div className="search_left flex">
                    <input type="text" placeholder="Search" name="keyword" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                  </div>


                  <div className="search_right flex">
                    <button className="gradient_button mr8" onClick={gameFinder}>Search</button>
                    <div className="filter_icon" onClick={filterMenuHandler}>
                      <span>Filter</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4952 0C10.9121 0 11.25 0.335938 11.25 0.750339C11.25 0.917196 11.1941 1.07929 11.091 1.211L6.75483 6.75305L6.75 10.2919C6.75 10.4909 6.67098 10.6818 6.53033 10.8225L5.37803 11.9753C5.34874 12.0046 5.30126 12.0046 5.27197 11.9753C5.2579 11.9612 5.25 11.9422 5.25 11.9223V6.75305L0.909034 1.211C0.6531 0.883894 0.712379 0.412474 1.04144 0.158057C1.17393 0.0556167 1.33699 0 1.50485 0H10.4952Z" fill="#30313D" />
                      </svg>
                    </div>

                  </div>

                </div>
              </div>



              <div className="search_bar">

                <div className="search_pop_wrapper" style={{
                  display: filtemenu ? 'block' : 'none'
                }}>
                  <div className="search_pop">
                    <div className="close_icon">
                      <svg onClick={() => setFiltermenu(false)} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" fill="#08090B" />
                        <path d="M22.5 13.5L13.5 22.5M13.5 13.5L22.5 22.5M33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>

                    <div className="search_pop_heading">
                      <p>What game you’re looking for ?</p>
                    </div>

                    <div className="search_filter_wrapper">
                      <div className="left">
                        <div className="search_pop_filter">
                          {
                            ['Ratings', 'Genres', 'Platforms', 'Stores'].
                              map(item => (
                                <div className={"filter_item " + `${item === filter && 'active'}`} onClick={() => filterChangeHandler(item)}>
                                  <Plus />
                                  <span>{item}</span>
                                </div>
                              ))
                          }
                        </div>
                      </div>
                      {/* <div className="right">
          <p>Clear Filters</p>
        </div> */}
                    </div>

                    {filter == "Ratings" &&
                      <div className={`"pop_option_wrapper active"`}>
                        <div className="pop_option_main">
                          <div className="pop_heading flex">
                            <div className="left">
                              <p>Filter by Ratings</p>
                            </div>
                            <div className="right">
                              <button className='apply' onClick={applyFilter}>Apply Filters</button>
                              <button className='clear' onClick={clearFilter}>Clear All</button>
                            </div>
                          </div>
                          <div className="pop_slider flex">


                            {
                              reviewKeys.map((item, i) => (

                                <div className="slider_input_main">
                                  <div className="slider_heading">
                                    <p>{reviewKeysName[i]}</p>
                                  </div>

                                  <div className="slider_input_wrapper">

                                    <div className="slider_input flex">
                                      <div className="left">
                                        <input type="number" className='number' placeholder='Min'
                                          value={filterRating[item][0]} min={1} max={10} onChange={(e) => ratingInputHandler(e, item, 0)} />
                                      </div>
                                      <div className="middle text-center">
                                        <p>To</p>
                                      </div>
                                      <div className="right">
                                        <input type="number" className='number' placeholder='Max' value={filterRating[item][1]} min={1} max={10} onChange={(e) => ratingInputHandler(e, item, 1)} />
                                      </div>
                                    </div>

                                    <div className="range_slider_wrapper">
                                      <RangeSlider rangeSlideDisabled={true} step={1} onInput={(e) => ratingSliderInput(e, item)} max={max} min={min} value={filterRating[item]} />

                                    </div>



                                  </div>

                                </div>
                              ))
                            }
                          </div>

                        </div>
                      </div>
                    }


                    {filter == "Genres" &&
                      <div className={`"pop_option_wrapper active"`}>
                        <div className="pop_option_main">
                          <div className="pop_heading flex">
                            <div className="left">
                              <p>Filter by Genres</p>
                            </div>
                            <div className="right">
                              <button className='apply' onClick={applyFilter}>Apply Filters</button>
                              <button className='clear' onClick={clearFilter}>Clear All</button>
                            </div>
                          </div>
                          <div className="pop_slider flex">

                            <div className="checkbox_wrapper">
                              {
                                tools.genre.map(item => (
                                  <div className="checkbox_item flex">
                                    <input type="checkbox" name={item} id={item} onChange={(e) => genreHandler(e, item)} checked={genre[item] ? true : false} />
                                    <label htmlFor={item}>{item}</label>
                                  </div>
                                ))
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    }

                    {filter == "Platforms" &&
                      <div className={`"pop_option_wrapper active"`}>
                        <div className="pop_option_main">
                          <div className="pop_heading flex">
                            <div className="left">
                              <p>Filter by Platforms</p>
                            </div>
                            <div className="right">
                              <button className='apply' onClick={applyFilter}>Apply Filters</button>
                              <button className='clear' onClick={clearFilter}>Clear All</button>
                            </div>
                          </div>
                          <div className="pop_slider flex">

                            <div className="checkbox_wrapper">
                              {
                                tools.platform.map(item => (
                                  <div className="checkbox_item flex">
                                    <input type="checkbox" name={item} id={item} onChange={(e) => platformHandler(e, item)} checked={platform[item] ? true : false} />
                                    <label htmlFor={item}>{item}</label>
                                  </div>
                                ))
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    }

                    {filter == "Stores" &&
                      <div className={`"pop_option_wrapper active"`}>
                        <div className="pop_option_main">
                          <div className="pop_heading flex">
                            <div className="left">
                              <p>Filter by Stores</p>
                            </div>
                            <div className="right">
                              <button className='apply' onClick={applyFilter}>Apply Filters</button>
                              <button className='clear' onClick={clearFilter}>Clear All</button>
                            </div>
                          </div>
                          <div className="pop_slider flex">

                            <div className="checkbox_wrapper">
                              {
                                tools.store.map(item => (
                                  <div className="checkbox_item flex">
                                    <input type="checkbox" name={item.title} id={item.title} onChange={(e) => storeHandler(e, item.title)} checked={store[item.title] ? true : false} />
                                    <label htmlFor={item.title}>{item.title}</label>
                                  </div>
                                ))
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    }


                  </div>
                </div>

              </div>




            </div>

            {filterIsLoading ?
              <div className="loader"></div>
              :

              data.length > 0 ?
                <Game data={data} favourite={favourite} />

                :

                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h2 className="notfound">Not found</h2>
                    </div>
                  </div>
                </div>




            }

            {(!filterIsLoading && totalPages > 1) &&
              <div className="container mb32">
                <Pagination count={totalPages} page={currentPage} onClick={pagination} />
              </div>
            }

          </>
      }


      <Footer />
    </>
  )
}

export default GamePage
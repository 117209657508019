import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from "recharts";

import { useEffect, useMemo, useState } from "react";
import { math } from "../../helpers/functions";
const lastDays = (day) => {
  var lastDays = []
  for (var i = 7; i > 0; i--) {
    var date = new Date()
    date.setDate(date.getDate() - (i - 1))
    lastDays.push(date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
  }
  return lastDays
}


const lastmonths = () => {
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
  return months
}


const lastYears = () => {
  var lastDays = []
  for (var i = 10; i > 0; i--) {
    var date = new Date()
    date.setDate(date.getDate() - (i - 1) * 365)
    lastDays.push(date.getFullYear())
  }
  return lastDays
}



const Chart = ({ graphList, type }) => {

  const [data, setData] = useState([])

  useEffect(() => {
    setData(graphList)
    const updateGraph = []
    let days = []
    if (type === 'Graph By Day') days = lastDays()
    if (type === 'Graph By Month') days = lastmonths()
    if (type === 'Graph By Year') days = lastYears()


    days.map((item, i) => {

      let filter = []

      if (type === 'Graph By Month') {
        filter = graphList.filter(filtertId => filtertId._id.date === (i < 10 ? "0" + (i + 1) : (i + 1).toString()))
      }

      else {
        filter = graphList.filter(filtertId => filtertId._id.date === item.toString())
      }

      if (filter.length > 0) {
        const data = filter[0]
        let total = (data.userReviews + data.critcReviews)
        updateGraph.push({
          ["Critic Rating"]: data.critcReviews > 0 ? math(data.criticRating / data.critcReviews) : "N/A",
          ["User Rating"]: data.userReviews > 0 ? math(data.userRting / data.userReviews) : "N/A",
          ["Overall Rating"]: total > 0 ? math((data.criticRating + data.userRting) / total) : "N/A",
          day: item

        })
      } else {
        updateGraph.push({ ['Critic Rating']: 0, ['User Rating']: 0, ["Overall Rating"]: 0, day: item })
      }

    })


    setData(updateGraph)


  }, [graphList])



  return (
    <>
      <ResponsiveContainer height={250}>

        <LineChart
          height={250}
          data={data}
        // margin={{
        //   top: 20,
        //   right: 0,
        //   left: -10,
        //   bottom: 55,
        // }}
        >
          <CartesianGrid vertical={false} stroke={"#48494e"} />
          <XAxis
            dataKey="day"
            tickLine={false}
            style={{ fontSize: "14px", fill: "#fff" }}
            color="#fff"
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            tickLine={false}
            axisLine={false}
            style={{ fontSize: "14px", fill: "#fff" }}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            tickLine={false}
            axisLine={false}
            style={{ fontSize: "0px", fill: "#000" }}
          />

          <Tooltip wrapperStyle={{ color: "red", stroke: "red", fill: "#000" }} />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="Critic Rating"
            stroke={"green"}
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="User Rating"
            stroke={"blue"}
          />

          <Line
            yAxisId="right"
            type="monotone"
            dataKey="Overall Rating"
            stroke={"orange"}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
export default Chart
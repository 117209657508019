import { Link } from "react-router-dom"
import CarouselAll from "../carousel/carousel"
import { SplideSlide } from "@splidejs/react-splide"
import { useState, useEffect } from "react"
import ReactHtmlParser from 'react-html-parser'
import { getToday } from "../../helpers/functions"
import { getAll } from "../../api"
import { imgCheck } from "../../helpers/functions"
const SortBy = ({ category = [], onChange, title, current }) => {

  return (
    <div className="row news_heading_row">
      <div className="col news_heading_col_left">
        <h3>{title}</h3>
      </div>
      <div className="col news_heading_col_right ">
        <div className="news_heading_right">
          <div className="sort">
            <p>Sort By :</p>
          </div>
          <div className="select">

            <select name="" id="" onChange={onChange}>
              <option value={""}>{"Sort By"}</option>
              {category.map(item => (
                <>
                  <option selected={current === item} value={item}>{item}</option>
                </>))}
            </select>
          </div>
        </div>

      </div>
    </div>

  )
}
const News = ({ type, data = [], categoryChange, category, currentCat }) => {


  const dynamicContent = (str) => {
    if (str) {
      var plainString = str.replace(/<[^>]+>/g, " ")
      plainString.replace(/\s{2,}/g, " ").trim()
      return `<p>${plainString.substring(0, 150)}${plainString.length > 149 ? '...' : ''}</p>`
    }
  }



  return (
    <>
      {
        type === 'slide' ? (

          <div className="container">
            <SortBy category={category} title="News" onChange={categoryChange} current={currentCat} />

            <div className="row">
              <CarouselAll className={"news_row"} page={3}>
                {
                  data.map(item => (
                    <SplideSlide>

                      <div className="news_wrapper">
                        <div className="news_img">
                          <img src={imgCheck(item.image, 0, 'news.png')} alt="" />
                        </div>
                        <div className="news_content">
                          <Link to={"/"} className="news_category">{item.category}</Link>
                          <h3>{item.title}</h3>
                          <div className="description">
                            {ReactHtmlParser(dynamicContent(item.description))}
                          </div>
                        </div>

                        <div className="news_user_wrapper">
                          <div className="news_user flex">
                            <div className="left flex">
                              <div className="img_left">
                                <img src={imgCheck(item.uploadedBy.image, 0, 'user.png')} alt="" />
                              </div>



                              <div className="img_right">
                                <p className="name">{item.uploadedBy.username}</p>
                                <p className="date">{getToday(item.createdAt)}</p>
                              </div>
                            </div>
                            <div className="right">
                              <Link to={"/news/" + item.url}>
                                <span>Open</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                  <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>

                    </SplideSlide>
                  ))
                }
              </CarouselAll>
            </div>

            <div className="browse_row row ">
              <Link to={"/news"}>
                <div className="col flex">
                  <h3>Browse all</h3>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Link>
            </div>

          </div>

        )

          : (
            <div className="container">

              <SortBy category={category} title="News" onChange={categoryChange} current={currentCat} />
              <div className="row">
                <div className={"news_row row nonslider_news"} >
                  {
                    data.map(item => (
                      <div className="news_col col-lg-4 col-md-6">
                        <div className="news_wrapper">
                          <div className="news_img">
                            <img src={imgCheck(item.image, 0, 'news.png')} alt="" />
                          </div>
                          <div className="news_content">
                            <Link to={"/"} className="news_category">{item.category}</Link>
                            <h3>{item.title}</h3>
                            <div className="description">
                              {ReactHtmlParser(dynamicContent(item.description))}
                            </div>
                          </div>

                          <div className="news_user_wrapper">
                            <div className="news_user flex">
                              <div className="left flex">
                                <div className="img_left">
                                  <img src={imgCheck(item.uploadedBy.image, 0, 'user.png')} alt="" />
                                </div>


                                <div className="img_right">
                                  <p className="name">{item.uploadedBy.username}</p>
                                  <p className="date">{getToday(item.createdAt)}</p>
                                </div>
                              </div>
                              <div className="right">
                                <Link to={"/news/" + item.url}>
                                  <span>Open</span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                    <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </Link>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>



            </div>

          )

      }
    </>
  )
}

export default News
import { Icon } from '@iconify/react';
import { useDropzone } from "react-dropzone";
import { useState, useContext, useEffect, } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../Context/UserContext';
import MultiSelect from '../multi-select/multi-select';
import { createOne, fileUpload, fileUploadMulti, getAll, updateOne } from "../../api";
import { imgUrl } from '../../helpers/functions';
const emptyValue = {
  title: '',
  developer: '',
  download: '',
  studio: '',
  publisher: '',
  trailer: '',
  description: '',
  feature: [],
  platform: [],
  genre: [],
  brand: [],
  store: [],
  description: '',
  featured: 0,
  fname: "",
  lname: "",
  email: "",
  website: "",
  press_kit: "",
  release_date: ""
}

const SubmitGame = ({ editId = '', submit = function () { } }) => {
  const [oldTitle, setoldTitle] = useState("")
  const [cover, setCover] = useState([])
  const [image, setImage] = useState([])
  const [video, setVideo] = useState("")
  const [value, setValue] = useState(emptyValue)
  const [tools, setTools] = useState({
    store: []
  })
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useContext(UserContext);
  useEffect(() => {
    if (editId) {
      if (editId.release_date) {
        editId.release_date = editId.release_date.split("T")[0]
      }
      setoldTitle(editId.title)
      setValue(editId)

      setStoreLink(editId.store)
      setImage(editId.image)
      setCover(editId.cover)
      setVideo(editId.video)
    }
  }, [editId])

  useEffect(() => {
    getAll('/games/tools')
      .then(({ data }) => {
        if (data.success) setTools(data.data)
        setIsLoading(false)
      })
  }, [])



  const { getRootProps: getRootThubProps, getInputProps: getInputThubProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return;
      }
      const { data } = await fileUpload('/media/uploads', file, 'game')
      if (data.data) {
        setImage(data.data)
      }
    },
  });

  const { getRootProps: getRootCoverProps, getInputProps: getInputCoverProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return;
      }
      console.log(acceptedFiles)
      const { data } = await fileUpload('/media/uploads', file, 'banner')
      if (data.data) {
        setCover(data.data)
      }
    },
  });

  const { getRootProps: getRootVideoProps, getInputProps: getInputVideoProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return; // Do not proceed further
      }
      const { data } = await fileUpload('/media/uploads', file)
      if (data.url) {
        setVideo(data.url)
      }
    },
  });

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const onMultiChange = (e, type) => {
    setValue({
      ...value,
      [type]: e
    })
  }

  const [selectGenre, setSelectGenre] = useState([])
  const changeGenre = (e) => {
    setSelectGenre(e)
  }


  const [storeLink, setStoreLink] = useState([])

  const addMore = () => {
    const copyValue = [...storeLink]
    copyValue.push({
      title: '',
      link: '',
      image: ''
    })
    setStoreLink(copyValue)
  }

  const removeHandler = (index) => {
    const copyValue = [...storeLink]
    copyValue.splice(index, 1)
    setStoreLink(copyValue)
  }

  const [brand, setBrand] = useState()
  const onBrandChange = (e, index) => {
    const splitSelect = e.target.value.split(',')
    setValue({ ...value, brand: [{ title: splitSelect[0], image: splitSelect[1] }] })
  }


  const onStoreTitleChange = (e, index) => {
    const copyValue = [...storeLink]
    const splitSelect = e.target.value.split(',')
    copyValue.splice(index, 1, { ...copyValue[index], title: splitSelect[0], image: splitSelect[1] })
    setStoreLink(copyValue)
  }

  const onStoreLinkChange = (e, index) => {
    const copyValue = [...storeLink]
    copyValue.splice(index, 1, { ...copyValue[index], [e.target.name]: e.target.value })
    setStoreLink(copyValue)
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (value.email.trim() && value.title.trim()) {
      try {
        const submitData = {
          ...value,
          oldTitle: oldTitle,
          image: image,
          cover: cover,
          video: video,
          store: storeLink
        }

        setIsLoading(true)


        const { data } = editId ? await updateOne("/games/" + editId._id, submitData) :
          await createOne("/games/create", submitData);

        if (data.success) {
          setValue(emptyValue)
          setImage([])
          setCover([])
          setVideo("")
          setStoreLink([])
          toast.success("Game added successfully");
        }
        if (data.exist) {
          toast.error(data.msg);
        }
        setIsLoading(false);
        submit()
      } catch (error) {
        console.error("Error adding game:", error);
        toast.error(error.response.data.msg);
      } finally {
        setIsLoading(false);
      }

    } else {
      toast.error("Please fill all the asterisk fields");
    }
  };


  return (
    <>
      <div className="container">


        {isLoading &&
          <div className="loader"></div>
        }

        <>
          <form onSubmit={handleSubmit} style={{
            display: isLoading ? 'none' : 'block'
          }}>
            <div className="submit_game_heading_row row" >
              <div className="col">
                <h1><Icon icon="teenyicons:game-controller-outline" width="36" height="36" /> Suggest Game</h1>
              </div>
            </div>

            <div className="submit_game_row row mt16">
              <div className="col game_half_input">


                <div className="game_input">
                  <label htmlFor="">First Name</label>
                  <input type="text" name="fname" id="" placeholder='Enter your first name'
                    value={value.fname}
                    onChange={onChange}
                  />
                </div>

                <div className="game_input">
                  <label htmlFor="">Last Name</label>
                  <input type="text" name="lname" id="" placeholder='Enter your last name'
                    value={value.lname}
                    onChange={onChange}
                  />
                </div>

                <div className="game_input">
                  <label htmlFor="">Email <span className='input_star'>*</span></label>
                  <input type="email" name="email" id="" placeholder='Enter your email'
                    value={value.email}
                    onChange={onChange}
                  />
                </div>

                <div className="game_input">
                  <label htmlFor="">Game Title <span className='input_star'>*</span></label>
                  <input type="text" name="title" id="" placeholder='Enter Game Title'

                    value={value.title}
                    onChange={onChange}
                  />
                </div>


                {user.type !== "User" && <>

                  <div className="game_input">
                    <label htmlFor="">Game Developer</label>
                    <input type="text" name="developer" id="" placeholder='Enter Developer Name'
                      value={value.developer}
                      onChange={onChange} />
                  </div>

                  <div className="game_input">
                    <label htmlFor="">Game Publisher</label>
                    <input type="text" name="publisher" id="" placeholder='Enter Publisher Name'
                      value={value.publisher}
                      onChange={onChange}
                    />
                  </div>

                  <div className="game_input">
                    <label htmlFor="">Game Website</label>
                    <input type="text" name="website" id="" placeholder='Enter Game website'
                      value={value.website}
                      onChange={onChange}
                    />
                  </div>

                  <div className="game_input">
                    <label htmlFor="">Press Kit Website</label>
                    <input type="text" name="press_kit" id="" placeholder='Enter Press Kit Website'
                      value={value.press_kit}
                      onChange={onChange}
                    />
                  </div>


                  <div className="game_input">
                    <label htmlFor="">Download code</label>
                    <input type="text" name="download" id="" placeholder='Enter Download Code'
                      value={value.download}
                      onChange={onChange} />
                  </div>



                  <div className="game_input">
                    <label htmlFor="">Trailer Link</label>
                    <input type="text" name="trailer" id="" placeholder='Enter Trailer Link'

                      value={value.trailer}
                      onChange={onChange}
                    />
                  </div>



                  {tools && tools.feature &&
                    <div className="game_input">
                      <MultiSelect label={"Game Features"} placeholder={"Select game features"} options={tools.feature} onChange={onMultiChange} type={"feature"} value={value.feature} />
                    </div>
                  }
                  {tools && tools.platform &&
                    <div className="game_input">
                      <MultiSelect label={"Platform"} placeholder={"Select platform"} options={tools.platform} onChange={onMultiChange} type={"platform"} value={value.platform} />
                    </div>
                  }

                  {tools && tools.genre &&
                    <div className="game_input">
                      <MultiSelect label={"Genre"} placeholder={"Select genre"} options={tools.genre} onChange={onMultiChange} type={"genre"} value={value.genre} />
                    </div>
                  }

                  {tools && tools.brand &&
                    <div className="game_input">

                      <label htmlFor="">Brand</label>
                      <select name="title" onChange={onBrandChange}>
                        <option value="">Select Brand</option>


                        {
                          tools.brand.map(item => (

                            <option selected={value.brand.length > 0 && value.brand[0].title === item.title} value={item.title + ',' + item.image}>
                              {item.title}

                            </option>
                          ))
                        }


                      </select>

                      {/* <MultiSelect label={"Brand"} placeholder={"Select brand"} options={tools.brand} onChange={onMultiChange} type={"brand"} value={value.brand} /> */}
                    </div>
                  }

                </>

                }


              </div>
            </div>


            <div className="submit_game_row row">
              <div className="col">

                {user.type !== "User" ? <>


                  <div className="game_input single_input">
                    <label htmlFor="">Release Date</label>
                    <input type="date" name="release_date" id="" placeholder='Enter Release Date'
                      value={value.release_date}
                      onChange={onChange}
                    />
                  </div>

                  {user.type === 'Admin' &&
                    <div className="game_input single_input">
                      <label htmlFor="">Show in Home Page Carousel</label>
                      <select name="featured" onChange={onChange}>
                        <option value="">Home page</option>
                        <option selected={value.featured == 1} value={1}>Yes</option>
                        <option selected={value.featured == 0} value={0}>No</option>
                      </select>
                    </div>
                  }

                  <div className="game_input">


                    <div className="game_input mt16">
                      <label htmlFor="">Game Thumbnail</label>
                      <div className="video_input">
                        <div {...getRootThubProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                          <input id="thubnail" {...getInputThubProps()} />
                          {
                            image.length > 0 ? (
                              // Display image preview
                              <img
                                src={imgUrl(image[0])}
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: 200,
                                }}
                              />
                            )
                              : (<>
                                <div className="upload_input">
                                  <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                  <span>Uploads </span>
                                </div>

                              </>)}
                        </div>
                      </div>
                    </div>

                    <div className="game_input mt16">
                      <label htmlFor="">Game Cover Image</label>
                      <div className="video_input">
                        <div {...getRootCoverProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                          <input id="thubnail" {...getInputCoverProps()} />
                          {console.log("cover")}
                          {console.log(cover)}
                          {
                            cover.length > 0 ? (
                              // Display image preview
                              <img
                                src={imgUrl(cover[0])}
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: 200,
                                }}
                              />
                            )
                              : (<>
                                <div className="upload_input">
                                  <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                  <span>Uploads </span>
                                </div>

                              </>)}
                        </div>
                      </div>
                    </div>

                    <div className="game_input mt16">
                      <label htmlFor="">Game Videos</label>

                      <div className="video_input">
                        <div {...getRootVideoProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                          <input id="thubnail" {...getInputVideoProps()} />
                          {video ? (
                            // Display image preview
                            <video
                              src={video}
                              alt="Preview"
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: 200,
                              }}
                            />
                          )
                            : (<>
                              <div className="upload_input">
                                <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                <span>Uploads </span>
                              </div>

                            </>)}
                        </div>
                      </div>
                    </div>

                    <div className="game_input game_description">
                      <label htmlFor="">Game Description</label>
                      <textarea name="description" id="" placeholder='Write Brief Game Description'
                        value={value.description}
                        onChange={onChange}
                      ></textarea>
                    </div>



                    {
                      tools.store &&
                      storeLink.map((item, i) => (
                        <div className="store_box">
                          <Icon icon="carbon:close-filled" width="32" height="32" onClick={() => removeHandler(i)} />
                          <div className="game_input">
                            <label htmlFor="">Store name</label>

                            <select name="title" onChange={(e) => onStoreTitleChange(e, i)} required>
                              <option value="">Select Store</option>
                              {
                                tools.store.map(item => (
                                  <option selected={item.title === storeLink[i].title} value={item.title + ',' + item.image}>{item.title}</option>
                                ))
                              }


                            </select>
                          </div>
                          <div className="game_input">
                            <label htmlFor="">Game Link</label>
                            <input type="text" name="link" onChange={(e) => onStoreLinkChange(e, i)} value={storeLink[i].link} required />
                          </div>
                        </div>
                      ))
                    }

                    {tools.store.length > storeLink.length &&
                      <button type='button' className='text-white gradient_button add_more' onClick={addMore}>Add Store Link</button>
                    }
                    <div className="game_submit_button mt16">
                      {/* <button type='button' className='white'>Draft</button> */}
                      <button type='submit' className='text-white'>{editId ? 'Update' : 'Submit'} Game</button>
                    </div>

                  </div>

                </>


                  :
                  <>
                    <div className="game_input">
                      <label htmlFor="">Game Website</label>
                      <input type="text" name="website" id="" placeholder='Enter Game website'
                        value={value.website}
                        onChange={onChange}
                      />
                    </div>


                    <div className="game_input">
                      <div className="game_submit_button mt16">
                        {/* <button type='button' className='white'>Draft</button> */}
                        <button type='submit' className='text-white'>{editId ? 'Update' : 'Submit'} Game</button>
                      </div>
                    </div>

                  </>
                }


              </div>

            </div>


          </form >

        </>
      </div >
    </>
  )
}

export default SubmitGame

import { useState, useEffect, useRef } from "react"
import Header from "../../../Components/header/header"
import Footer from "../../../Components/footer/footer"
import ProfileMenu from "../../../Components/profile/profile-menu"
import UserPopup from './review_popup'
import ReviewPopup from "../../../Components/popup/review-popup"
import { Icon } from '@iconify/react';
import Pagination from "../../../Components/pagination/pagination"
import {
  getAll
} from "../../../api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom"
import { getToday } from "../../../helpers/functions"

const ViewUser = () => {

  const ITEMS_PER_PAGE = 10;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!keyword) {
      getData();
    } else {
      paginationData(currentPage)
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/reviews', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,

      });

      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const paginationData = async (currentPage) => {
    const trimKeyword = keyword.trim()
    if (trimKeyword) {
      setCurrentPage(currentPage)
      setIsLoading(true);
      try {
        const res = await getAll('/admin/search', {
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          type: "reviews",
          keyword: trimKeyword
        });
        if (res.status === 200) {
          setData(res.data.data);
          setTotalPages(res.data.totalPages);
        } else {
          toast.error("Error While Fetching User Data");
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  }
  const pagination = (item) => {
    setCurrentPage(item)
  }


  const [popup, setPopup] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [popItem, setPopitem] = useState({})
  const popupHandler = (type, item) => {
    setPopup(true)
    setPopupType(type)
    setPopitem(item)
  }

  const closePopup = (refresh) => {
    setPopup(false)
    if (refresh) {
      getData();
    }
  }


  const [keyword, setKeyword] = useState("")

  const searchhandler = async () => {
    paginationData(1)
  }


  const [dot, setDot] = useState("")
  const dotHandler = (id) => {
    setDot(id)
  }

  const dotCloseHandler = () => {
    setDot("")
  }



  const [editreview, setEditReview] = useState(false)

  const popupcloseHandler = (close) => {
    setEditReview(false)
    setDot("")
    if (close) {
      getData();
    }
  }

  const editReviewHandler = (item) => {
    setEditReview(true)
    setPopitem(item)
  }

  // const editRef = useRef([])

  // useEffect(() => {
  //   const selectClick = (e) => {

  //     console.log(dot)
  //     console.log(editRef.current[dot])

  //     if (editRef.current[dot] && !editRef.current[dot].contains(e.target)) {

  //       // setDot(false)
  //     }
  //   };
  //   window.addEventListener("click", selectClick);
  //   return () => window.removeEventListener("click", selectClick);
  // }, [])



  return (
    <>

      <ReviewPopup close={popupcloseHandler} isPopup={editreview} gameData={popItem} mode={"edit"} />


      <UserPopup isPopup={popup} close={closePopup} popupType={popupType} item={popItem} category={category} />

      <Header />
      <div className="container admin_container">
        <ProfileMenu className={"flex"}>

          {isLoading ? (

            <div className="loader"></div>
          )
            :
            <>
              <div className="game_heading_wrapper">
                <div className="game_heading_left">
                  <div className="game_heading">
                    <h1>
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M10.5 12.75H18M10.5 18H22.5M14.5256 27H24.3C26.8202 27 28.0804 27 29.043 26.5095C29.8897 26.0781 30.5781 25.3897 31.0095 24.543C31.5 23.5804 31.5 22.3202 31.5 19.8V11.7C31.5 9.17976 31.5 7.91965 31.0095 6.95704C30.5781 6.11031 29.8897 5.4219 29.043 4.99047C28.0804 4.5 26.8202 4.5 24.3 4.5H11.7C9.17976 4.5 7.91965 4.5 6.95704 4.99047C6.11031 5.4219 5.4219 6.11031 4.99047 6.95704C4.5 7.91965 4.5 9.17976 4.5 11.7V30.5033C4.5 31.3025 4.5 31.7022 4.66384 31.9074C4.80633 32.0859 5.0224 32.1898 5.25081 32.1895C5.51344 32.1892 5.82551 31.9396 6.44963 31.4403L10.0278 28.5777C10.7588 27.993 11.1243 27.7006 11.5312 27.4927C11.8923 27.3082 12.2766 27.1734 12.6738 27.0919C13.1215 27 13.5895 27 14.5256 27Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      Reviews List</h1>
                  </div>


                </div>
                <div className="game_heading_right">
                  <div className="input_item">
                    <Icon icon="prime:search" width="24" height="24" />
                    <input placeholder="Search" type="text" name="" id="" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                  </div>
                  <div className="filter_icon">
                    <button onClick={searchhandler}>Search</button>
                  </div>

                </div>
              </div>


              <div className=" mt16">


                {data.map((item, i) => (
                  <div className="review_row flex">

                    {/* {item.attachment &&
                        <div className="review_image mt16">
                          <img src={item.attachment} alt="" />
                        </div>
                      } */}

                    {item.attachment &&
                      <div className="left">
                        <img src={item.attachment} alt="" />
                      </div>
                    }
                    <div className="right">
                      <div className="heading flex">
                        <div className="heading_left flex">
                          {item.game &&
                            <div className="heading_title">
                              <h2>{item.game.title}</h2>
                            </div>
                          }


                        </div>

                        <div className="heading_right flex">
                          <div className="heading_status flex">
                            <div className="heading_status_button">
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M11 5.54287V6.00287C10.9994 7.08108 10.6503 8.13021 10.0047 8.99378C9.35909 9.85736 8.45165 10.4891 7.41769 10.7948C6.38372 11.1005 5.27863 11.0638 4.26724 10.6902C3.25584 10.3165 2.39233 9.62592 1.80548 8.7214C1.21863 7.81689 0.939896 6.7469 1.01084 5.67102C1.08178 4.59515 1.4986 3.57103 2.19914 2.7514C2.89968 1.93177 3.8464 1.36055 4.8981 1.12294C5.9498 0.885317 7.05014 0.99403 8.03501 1.43286M11 2L6.00001 7.00501L4.5 5.50501" stroke="#228403" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span>{item.status}</span>
                            </div>

                          </div>

                          <div className="heading_date flex">
                            <span>{getToday(item.createdAt)}</span>
                          </div>

                          {/* ref={el => editRef.current[i] = el} */}
                          <div className="heading_dot">
                            <svg onClick={() => dotHandler(i)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            {dot === i &&
                              <div className="review_edit_box">
                                <p onClick={() => editReviewHandler(item)}>Edit</p>
                                <p onClick={() => popupHandler('delete', item)}>Delete</p>
                                <p onClick={dotCloseHandler}>Close <Icon icon="carbon:close-filled" width="16" height="16" /></p>
                              </div>
                            }
                          </div>

                        </div>


                      </div>
                      <div className="review_description_wrapper mt8 flex">
                        <div className="description">
                          <p>{item.review}</p>
                        </div>
                        <div className="tag_right">
                          <span>{item.rating}</span>
                        </div>

                      </div>


                      <div className="reviews_tag flex">
                        {/* <div className="tag_left flex">
                          <div className="tag_item">
                            <span className="name">Platform:</span>
                            <span className="value">Playstation</span>
                          </div>

                          <div className="tag_item">
                            <span className="name">Genre:</span>
                            <span className="value">Action-Adventure, Survival Horror, Drama</span>
                          </div>

                        </div> */}

                      </div>
                    </div>
                  </div>
                ))}


              </div>

              {totalPages > 1 &&
                <Pagination count={totalPages} page={currentPage} onClick={pagination} />
              }

            </>

          }
        </ProfileMenu>

      </div>
      <Footer />


    </>

  )
}
export default ViewUser



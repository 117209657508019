import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Context/ProtectedRoute";

import HomePage from "./Pages/home";
import Profile from "./Pages/profile";

import Game from "./Pages/game";
import Mygame from './Pages/my-game'
import SingleGame from "./Pages/single-game";
import News from "./Pages/news";
import SingleNews from "./Pages/single-news";

import Signup from "./Pages/signup";
import Login from "./Pages/login";
import Contact from "./Pages/contact";
import SubmitGame from "./Pages/submit-game";
import Compare from './Pages/compare'

import Platforms from './Pages/platforms'

import PrivacyPolicy from "./Pages/privacy-policy";
import { UserProvider } from "./Context/UserContext";
import Development from './Components/development/development'


// Below admin routes 
import ViewUser from './Admin/Components/user/view_user'
import ViewNews from './Admin/Components/news/view_news'
import ViewnewsCategory from './Admin/Components/news-category/view_news_cat'


import ViewGenre from './Admin/Components/genre/view_genre'
import ViewStore from './Admin/Components/store/view_store'
import ViewGame from './Admin/Components/game/view_game'
import Reviews from "./Admin/Components/review/view_reviews";


function App() {
  const [load, setLoad] = useState(false)
  const [development, setDevelopment] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("development")) {
      setDevelopment(true)
    }
    setLoad(true)
  }, [])

  return (
    <>
      {(!development && load) && <Development />}
      {(development && load) && (
        <Router>
          <UserProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />


              <Route path="/games/top-games" element={<Game type="trending" title={"Top Games"} />} />
              <Route path="/games/top-rated" element={<Game type="rating" title={"Top rated game"} />} />
              <Route path="/games/recommended" element={<Game type="recommend" title={"Recommended game"} />} />
              <Route path="/games/compare" element={<Compare />} />
              <Route path="/brands" element={<Platforms />} />


              <Route path="/games/:id" element={<SingleGame />} />

              <Route path="/news" element={<News />} />
              <Route path="/news/:id" element={<SingleNews />} />


              {/* Below General pages */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              {/* <Route path="/forget" element={<Forget />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/search" element={<SearchResult />} />
            <Route path="/gamereview/:id" element={<GameReview />} />
            <Route path="/news/:id" element={<SingleNews />} />
            <Route
              path="/OAuthRedirecting/:token"
              element={<HandleAuthLogin />}
            /> */}
              {/* <Route path="/" element={<ProtectedRoute />}>
              <Route path="/submitgame" element={<SubmitGame />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/mygames" element={<MyGames />} />
            </Route> */}


              {/* Admin and protected routes start */}

              <Route path="/" element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/suggest-game" element={<SubmitGame />} />
                <Route path="/games/my-game" element={<Mygame />} />
              </Route>

              <Route path="/" element={<ProtectedRoute type={"Admin"} />}>
                <Route path="/admin/reviews" element={<Reviews />} />
                <Route path="/admin/games" element={<ViewGame />} />
                <Route path="/admin/news" element={<ViewNews />} />
                <Route path="/admin/games/genre" element={<ViewGenre />} />
                <Route path="/admin/games/store" element={<ViewStore />} />
                <Route path="/admin/news/category" element={<ViewnewsCategory />} />
                <Route path="/admin/user/admin" element={<ViewUser type={"Admin"} />} />
                <Route path="/admin/user/employee" element={<ViewUser type={"Employee"} />} />
                <Route path="/admin/user/user" element={<ViewUser type={"User"} />} />
              </Route>
              {/* Admin routes end */}


            </Routes>
          </UserProvider>
          {/* <ScrollToTop /> */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Router>
      )}

    </>
  );
}

export default App;

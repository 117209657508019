import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
import { useEffect, useState } from "react"
import { getAll } from "../api"
const Platforms = () => {


  const [tools, setTools] = useState([])
  useEffect(() => {
    getAll('/games/tools')
      .then(({ data }) => {
        setTools(data.data.brand)
      })
  }, []);


  return (
    <>
      <Header />


      <div className="container">

        <div className="row">

          {
            tools.map(item => (
              <div className="col-lg-3 mb16">
                <div className="platform_box">
                  <img src={item.image} alt="" />
                  <h3>{item.title}</h3>
                </div>
              </div>
            ))
          }

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Platforms
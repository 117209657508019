import { useState, useRef, useEffect } from "react"
import { useDropzone } from "react-dropzone";
import { createOne, fileUpload, updateOne } from "../../api";
import { Icon } from '@iconify/react';
import { toast } from "react-toastify";

const defaultValue = {
  'gameplay': 1,
  'story': 1,
  'visuals': 1,
  'sound': 1,
  'controls': 1,
  'replayability': 1,
  'originality': 1,
  'value': 1,
  'technicalPerformance': 1,
  'accessibility': 1,
  'difficultyLevel': 1,
  'hourlyPlayed': 1,
  "review": ""
}
const ReviewPopup = ({ close, isPopup, gameData, mode }) => {

  useEffect(() => {

    if (gameData && mode == 'edit') {
      setOldValue(gameData)
      setValue(gameData)
      setGameImage(gameData.attachment)
    }

  }, [gameData, mode])

  const items = [
    'Game Play',
    'Story',
    'Visuals',
    'Sound',
    'Controls',
    'Replayability',
    'Originality',
    'Value',
    'Technical Performance',
    'Accessibility',
    'Difficulty Level',
    'Hourly Game Played'
  ]

  const names = [
    'gameplay',
    'story',
    'visuals',
    'sound',
    'controls',
    'replayability',
    'originality',
    'value',
    'technicalPerformance',
    'accessibility',
    'difficultyLevel',
    'hourlyPlayed'
  ]


  const [oldValue, setOldValue] = useState({})
  const [value, setValue] = useState(defaultValue)
  const valueHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }


  const [gameImage, setGameImage] = useState("");


  const { getRootProps: getRootThubProps, getInputProps: getInputThubProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return; // Do not proceed further
      }
      const { data } = await fileUpload('/media/uploads', file)
      if (data.url) {
        setGameImage(data.url)
      }
    },
  });




  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }


  const [isLoading, setIsLoading] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    try {

      const isEdit = mode !== "edit" ?
        createOne('/reviews/create', { ...value, attachment: gameImage, game: gameData._id })
        :
        updateOne('/reviews/' + gameData._id, { ...value, attachment: gameImage, game: gameData.game, oldValue: oldValue })
      const { data } = await isEdit


      if (data.success) {
        toast.success(data.msg)
        setGameImage("")
        setValue(defaultValue)
        close(true, data.game, data.review)
      }

    } catch (error) {
      toast.error("Error while adding review.");

      console.log("Error while adding review.", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="popup_wrapper" style={{
      display: `${isPopup ? 'block' : 'none'}`
    }} onClick={clickOutside}>
      <div className="game_review_slider global_scroll" ref={ref}>

        <div className="review_popup_text text-center">
          <Icon icon="material-symbols:tab-close" width="36" height="36" onClick={close} />

          <h2 className="">How's your experience?</h2>
          <p>Provide your ratings and general review for the game.</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="review_input_wrapper">
            {
              items.map((item, index) => (
                <div className="game_input">
                  <label htmlFor="">{item}</label>
                  <span className="total_point">{value[names[index]]}</span>
                  <input onChange={valueHandler} type="range" name={names[index]} id="" min={1} max={10} value={value[names[index]]} />
                </div>
              )
              )
            }

            <div className="game_input">
              <label htmlFor="">Your Review</label>
              <textarea name="review" onChange={valueHandler} id="" placeholder="Enter your review" value={value.review}></textarea>
            </div>


            <div className="game_input">
              <label htmlFor="">Review Image</label>
              <div className="image_input">
                <div {...getRootThubProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                  <input id="thubnail" {...getInputThubProps()} />
                  {
                    gameImage ? (
                      // Display image preview
                      <img
                        src={gameImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: 200,
                        }}
                      />
                    )
                      : (<>
                        <div className="upload_input">
                          <Icon icon="material-symbols:file-upload" width="24" height="24" />
                          <span>Uploads </span>
                        </div>

                      </>)}
                </div>
              </div>
            </div>



          </div>



          <div className="game_input">
            <button type="submit" className="gradient_button mt16">{mode === "edit" ? "Update" : "Add"} Review</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReviewPopup
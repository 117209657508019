import { useState, useRef, useEffect } from "react"
import { updateOne, deleteUser, deleteOne, createOne } from "../../../api";
import { toast } from "react-toastify";
import { Icon } from '@iconify/react';

const ReviewPopup = ({ close, isPopup, popupType, item, id }) => {
  const [isProcessing, setisProcessing] = useState(false);
  const [value, setValue] = useState([''])
  useEffect(() => {
    if (item && item.length > 0) {
      setValue(item)
    }
  }, [item])

  const onChange = (e, index) => {
    const copyValue = [...value]
    copyValue.splice(index, 1, e.target.value)
    setValue(copyValue)
  }


  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }

  const updateUser = async (e) => {
    e.preventDefault()
    if (id) {
      setisProcessing(true);
      try {
        const { data } = await updateOne("/news/category/" + id, {
          category: value
        });


        if (data.success) {
          setValue([])
          toast.success("Category Information Updated");
          close(true)
        }

      } catch (error) {
        console.error("Error:", error);
        toast.error(
          error.response.data.error || "Error updating New category"
        );
      } finally {
        setisProcessing(false);
      }
    }
  };


  const addMore = () => {
    const copyValue = [...value]
    copyValue.push('')
    setValue(copyValue)
  }

  const removeHandler = (index) => {
    const copyValue = [...value]
    copyValue.splice(index, 1)
    setValue(copyValue)
  }

  switch (popupType) {


    case 'edit':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {isProcessing ?
              <div className="loader"></div>
              :
              <div className="edit_box">

                <form onSubmit={updateUser}>
                  <div className="input_items category_input">

                    <div className="input_item">
                      <label htmlFor="">Category</label>

                      {value.map((item, i) => (
                        <>
                          <div className="item">
                            <input placeholder="Enter category name" onChange={(e) => onChange(e, i)} type="text" value={value[i]} />
                            <div className="category_remove_button">
                              <p onClick={() => removeHandler(i)}>Remove</p>
                            </div>
                          </div>

                        </>
                      ))}

                    </div>

                  </div>

                  <div className="remove_box">
                    <div className="button_item">
                      <button onClick={addMore} type="button" className="add gradient_button">Add More</button>
                      <button type="submit" className="delete gradient_button">Update</button>
                      <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            }

          </div>
        </div>
      )

    case 'view':
      return (

        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="view_data admin_table">
              <table className="">
                <tbody>
                  <tr>
                    <td>Category:</td>
                    <td>{item.category}</td>
                  </tr>
                  <tr>
                    <td>order:</td>
                    <td>{item.order}</td>
                  </tr>

                </tbody>
              </table>


              <div className="remove_box mt16">
                <div className="button_item">
                  <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      )


  }

}

export default ReviewPopup
function GradientSVG() {
  const idCSS = "hello";
  const gradientTransform = `rotate(90)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="#F9C3FF" />
          <stop offset="65.85%" stopColor="#8650FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GradientSVG;

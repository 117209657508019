
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import Progress from '../Components/progress/progress'
import ReviewPopup from '../Components/popup/review-popup'
import ReviewPopupView from "../Components/popup/review-popup-view";
import { Link, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import UserContext from "../Context/UserContext";
import { getAll, getOne } from "../api";
import { toast } from "react-toastify";
import { getToday, math, nan, score } from "../helpers/functions";
import Pagination from "../Components/pagination/pagination";
import Chart from "../Components/chart/Chart";
import { reviewKeys, reviewNames } from "../helpers/utility";
import { imgCheck } from "../helpers/functions";
function SingleGame() {
  const [user, setUser] = useContext(UserContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [criticCurrentPage, setCriticCurrentPage] = useState(1)
  const [criticReviews, setCriticReviews] = useState([])
  const [totalCriticPages, setTotalCriticPages] = useState(0)


  const [userCurrentPage, setUserCurrentPage] = useState(1)
  const [userReviews, setUserReviews] = useState([])
  const [totalUserPages, setTotalUserPages] = useState(0)

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const res = await getOne('/games/' + id);
      if (res.status === 200) {
        setData(res.data);
        setIsLoading(false);
      }

      if (res.data && res.data._id) {
        const review = await getOne('/reviews/game/' + res.data._id);
        setUserReviews(review.data.userReviews)
        setTotalUserPages(review.data.totalUserPages)
        setCriticReviews(review.data.criticReviews)
        setTotalCriticPages(review.data.totalCriticPages)
        await getOne(`/games/count/${res.data._id}`)
      }

    } catch (error) {
      console.error(error);
    }

  };


  const getReviews = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getOne('/reviews/' + id);
      if (res.status === 200) {
        setData(res.data);
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };



  const [popup, setPopup] = useState(false)
  const addReviewHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPopup(true)
  }

  const popupcloseHandler = (isOpen, data, review) => {
    setPopup(false)
    if (data) {
      setData(data)
    }

    if (review) {
      const updateReview = {
        ...review,
        user: {
          username: user.name,
          image: user.image
        }
      }
      if (review.type === "Employee") setCriticReviews([updateReview, ...criticReviews])
      if (review.type === "User") setUserReviews([updateReview, ...userReviews])

    }

  }


  const userPagination = async (item) => {
    if (userCurrentPage !== item) {
      const res = await getAll('/reviews/game/pagination', {
        gameId: data._id,
        page: item,
        type: "User"
      });


      if (res.data.success) {
        setUserReviews(res.data.reviews)
        window.location.href = "#user_review_box"
      }
    }
    setUserCurrentPage(item)
  }

  const criticPagination = async (item) => {
    if (criticCurrentPage !== item) {
      const res = await getAll('/reviews/game/pagination', {
        gameId: data._id,
        page: item,
        type: "Employee"
      });


      if (res.data.success) {
        setCriticReviews(res.data.reviews)
        window.location.href = "#critic_review_box"
      }
    }
    setCriticCurrentPage(item)
  }





  const [version, setVersion] = useState("Filter By Version");

  const versionHandler = async (e) => {
    const value = e.target.value
    setVersion(value)
    if (value !== "Filter By Version") {
      try {
        const { data } = await getAll('/reviews/game/filter/' + data.url, {
          version: value,
          type: "version"
        })

        if (data.success) {
          let copyGame = {
            userObj: {},
            criticObj: {},
            overallObj: {}
          }

          copyGame.userObj.gameplay = 0
          copyGame.userObj.story = 0
          copyGame.userObj.visuals = 0
          copyGame.userObj.sound = 0
          copyGame.userObj.controls = 0
          copyGame.userObj.replayability = 0
          copyGame.userObj.originality = 0
          copyGame.userObj.value = 0
          copyGame.userObj.technicalPerformance = 0
          copyGame.userObj.accessibility = 0
          copyGame.userObj.hourlyPlayed = 0
          copyGame.userObj.difficultyLevel = 0
          copyGame.userRating = 0
          copyGame.totalUserReview = 0
          copyGame.userRating = 0

          copyGame.criticObj.gameplay = 0
          copyGame.criticObj.story = 0
          copyGame.criticObj.visuals = 0
          copyGame.criticObj.sound = 0
          copyGame.criticObj.controls = 0
          copyGame.criticObj.replayability = 0
          copyGame.criticObj.originality = 0
          copyGame.criticObj.value = 0
          copyGame.criticObj.technicalPerformance = 0
          copyGame.criticObj.accessibility = 0
          copyGame.criticObj.hourlyPlayed = 0
          copyGame.criticObj.difficultyLevel = 0
          copyGame.criticRating = 0
          copyGame.totalCriticReview = 0
          copyGame.criticRating = 0


          copyGame.overallObj.gameplay = 0
          copyGame.overallObj.story = 0
          copyGame.overallObj.visuals = 0
          copyGame.overallObj.sound = 0
          copyGame.overallObj.controls = 0
          copyGame.overallObj.replayability = 0
          copyGame.overallObj.originality = 0
          copyGame.overallObj.value = 0
          copyGame.overallObj.technicalPerformance = 0
          copyGame.overallObj.accessibility = 0
          copyGame.overallObj.hourlyPlayed = 0
          copyGame.overallObj.difficultyLevel = 0
          copyGame.rating = 0
          copyGame.totalReviews = 0


          data.data.map((item, index) => {
            if (item.type === 'User') {
              copyGame.userObj.gameplay += item.gameplay
              copyGame.userObj.story += item.story
              copyGame.userObj.visuals += item.visuals
              copyGame.userObj.sound += item.sound
              copyGame.userObj.controls += item.controls
              copyGame.userObj.replayability += item.replayability
              copyGame.userObj.originality += item.originality
              copyGame.userObj.value += item.value
              copyGame.userObj.technicalPerformance += item.technicalPerformance
              copyGame.userObj.accessibility += item.accessibility
              copyGame.userObj.hourlyPlayed += item.hourlyPlayed
              copyGame.userObj.difficultyLevel += item.difficultyLevel
              copyGame.userRating += item.rating
              copyGame.totalUserReview += 1
            }

            else {
              copyGame.criticObj.gameplay += item.gameplay
              copyGame.criticObj.story += item.story
              copyGame.criticObj.visuals += item.visuals
              copyGame.criticObj.sound += item.sound
              copyGame.criticObj.controls += item.controls
              copyGame.criticObj.replayability += item.replayability
              copyGame.criticObj.originality += item.originality
              copyGame.criticObj.value += item.value
              copyGame.criticObj.technicalPerformance += item.technicalPerformance
              copyGame.criticObj.accessibility += item.accessibility
              copyGame.criticObj.hourlyPlayed += item.hourlyPlayed
              copyGame.criticObj.difficultyLevel += item.difficultyLevel
              copyGame.criticRating += item.rating
              copyGame.totalCriticReview += 1
            }


            copyGame.overallObj.gameplay += item.gameplay
            copyGame.overallObj.story += item.story
            copyGame.overallObj.visuals += item.visuals
            copyGame.overallObj.sound += item.sound
            copyGame.overallObj.controls += item.controls
            copyGame.overallObj.replayability += item.replayability
            copyGame.overallObj.originality += item.originality
            copyGame.overallObj.value += item.value
            copyGame.overallObj.technicalPerformance += item.technicalPerformance
            copyGame.overallObj.accessibility += item.accessibility
            copyGame.overallObj.hourlyPlayed += item.hourlyPlayed
            copyGame.overallObj.difficultyLevel += item.difficultyLevel
            copyGame.rating += item.rating
            copyGame.totalReviews += 1

            if ((data.data.length - 1) === index) {
              if (copyGame.totalReviews > 0) {
                copyGame.rating = score(copyGame.rating / copyGame.totalReviews);
              }

              if (copyGame.totalUserReview > 0) {
                copyGame.userRating = score(copyGame.userRating / copyGame.totalUserReview)
              }

              if (copyGame.criticRating > 0) {
                copyGame.criticRating = score(copyGame.criticRating / copyGame.totalCriticReview);
              }


            }
          })
          // setGame({ ...game, ...copyGame })
        }

      } catch (error) {
        toast.error(error.message)
      }
    } else {
      // setGame(oldGame)
    }
  }

  const [graph, setGraph] = useState("Filter By Graph")

  const [graphList, setgraphList] = useState([])
  const graphHandler = async (e) => {
    const value = e.target.value
    setGraph(value)
    const res = await getAll('/reviews/game/filter/' + data._id, {
      type: "date",
      graphType: value
    })
    if (res.data.success) setgraphList(res.data.data)
    window.location.href = "#graph"
  }

  const [reviewPopData, setReviewPopData] = useState("")
  const [reviewPopView, setReviewPopView] = useState(false)
  const reviewPopViewHandle = (data) => {
    setReviewPopData(data)
    setReviewPopView(true)
  }

  const reviewPopCloseHandle = () => {
    setReviewPopData("")
    setReviewPopView(false)
  }

  return (
    <>


      <ReviewPopupView close={reviewPopCloseHandle} isPopup={reviewPopView} gameData={reviewPopData} />
      <ReviewPopup close={popupcloseHandler} isPopup={popup} gameData={data} mode={"add"} />


      <Header />
      {isLoading ?
        <div className="loader"> </div>
        :
        <>

          {!data.video && data.cover &&
            <div className="game_video text-center mb16">
              <img src={data.cover} alt="" />
            </div>
          }

          {data.video &&
            <div className="game_video text-center">
              <video id="videoID" autoPlay controls src={data.video}></video>

              {/* <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.3327 28.0013C51.3327 40.8879 40.886 51.3346 27.9993 51.3346C15.1127 51.3346 4.66602 40.8879 4.66602 28.0013C4.66602 15.1147 15.1127 4.66797 27.9993 4.66797C40.886 4.66797 51.3327 15.1147 51.3327 28.0013ZM36.0568 26.4311L25.0421 19.3502C24.1053 18.748 23.6369 18.4469 23.2496 18.4745C22.912 18.4987 22.6016 18.6681 22.3987 18.939C22.166 19.2499 22.166 19.8067 22.166 20.9204V35.0822C22.166 36.1959 22.166 36.7527 22.3987 37.0636C22.6016 37.3345 22.912 37.504 23.2496 37.5281C23.6369 37.5557 24.1053 37.2546 25.0421 36.6524L36.0568 29.5715C36.8697 29.0489 37.2762 28.7876 37.4165 28.4554C37.5392 28.1651 37.5392 27.8375 37.4165 27.5472C37.2762 27.215 36.8697 26.9537 36.0568 26.4311Z" fill="#8651FF" />
                <path d="M24.8761 19.3501L35.8908 26.431C36.7037 26.9535 37.1102 27.2148 37.2505 27.5471C37.3732 27.8374 37.3732 28.165 37.2505 28.4553C37.1102 28.7875 36.7037 29.0488 35.8908 29.5714L24.8761 36.6523C23.9393 37.2545 23.4709 37.5556 23.0836 37.5279C22.746 37.5038 22.4355 37.3343 22.2327 37.0634C22 36.7525 22 36.1957 22 35.0821V20.9203C22 19.8066 22 19.2498 22.2327 18.9389C22.4355 18.668 22.746 18.4985 23.0836 18.4744C23.4709 18.4467 23.9393 18.7478 24.8761 19.3501Z" fill="white" />
              </svg> */}

            </div>
          }

          <div className="container">
            <div className="row single_game_row">
              <div className="col single_game_col">


                <div className="game_single row">
                  <div className="left col-md-3">
                    <div className="img">
                      <img src={imgCheck(data.image, 0, 'game.png')} alt="" />
                    </div>

                    {data.genre &&
                      <div className="genre">
                        <h3>Genre :</h3>
                        <div className="genre_items">
                          {
                            data.genre.map(item => (
                              <span>{item.label}</span>
                            ))
                          }
                        </div>
                      </div>
                    }

                    {data.platform &&
                      <div className="genre">
                        <h3>Platform :</h3>
                        <div className="genre_items">
                          {
                            data.platform.map(item => (
                              <span>{item.label}</span>
                            ))
                          }
                        </div>
                      </div>
                    }
                    {data.feature &&
                      <div className="genre">
                        <h3>Features :</h3>
                        <div className="genre_items">
                          {
                            data.feature.map(item => (
                              <span>{item.label}</span>
                            ))
                          }
                        </div>
                      </div>
                    }


                  </div>
                  <div className="right col-md-9">

                    <div className="game_details flex">
                      <div className="game_date">
                        <p className="release">Release</p>

                        <p className="date">{data.release_date ? getToday(data.release_date) : "N/A"}</p>

                      </div>
                      <div className="game_developer">
                        <p className="release">Developed By</p>
                        <p className="date">{data.developer ? data.developer : "N/A"}</p>
                      </div>

                      <div className="game_developer">
                        <p className="release">Published By</p>
                        <p className="date">{data.publisher ? data.publisher : "N/A"}</p>
                      </div>
                    </div>

                    <div className="game_heading">
                      <div className="title">
                        <h1>{data.title}</h1>
                      </div>
                      {/* <div className="game_button">
                    <button>View Game Details</button>
                  </div> */}

                    </div>

                    <div className="game_rating flex">
                      <span className="mauto">Overall Rating : </span>
                      <span className="mauto"> {data.totalReviews === 0 ? "N/A" : score(data.rating)}</span>
                    </div>

                    <div className="game_rating_circle flex">
                      <div className="game_rating_circle_item flex">
                        <div className="circle_left">
                          <Progress width={60} height={60} value={score(data.criticRating)} />
                        </div>
                        <div className="circle_right">
                          <span>Critic Rating</span>
                        </div>
                      </div>
                      <div className="game_rating_circle_item flex">
                        <div className="circle_left">
                          <Progress width={60} height={60} value={score(data.userRating)} />
                        </div>
                        <div className="circle_right">
                          <span>User Rating</span>
                        </div>
                      </div>

                      <div className="game_rating_circle_item_btn">
                        {/* <button>Buy Now</button> */}
                        <button onClick={addReviewHandler}>Add a review</button>
                      </div>
                    </div>

                    <div className="single_game_description mt32">
                      <div className="game_description_heading">
                        <h3>Description:</h3>
                      </div>
                      <div className="game_description_content text-white">
                        {data.description}
                      </div>


                      <div className="game_description_extra mt16">
                        {data.website &&
                          <div className="game_description_content text-white">
                            <p>Game website: <a className="outside_link" href={data.website} target="_blank">View Game website</a></p>
                          </div>
                        }
                        {/* {data.press_kit &&
                          <div className="game_description_content text-white">
                            <p>Press kit website: <a className="outside_link" href={data.press_kit} target="_blank">View Press Kit website</a></p>
                          </div>
                        }
                        {data.download &&
                          <div className="game_description_content text-white">
                            <p>Download code: <a className="outside_link" href={data.download} target="_blank">Download Code</a></p>
                          </div>
                        }
                        {data.trailer &&
                          <div className="game_description_content text-white">
                            <p>Trailer link: <a className="outside_link" href={data.trailer} target="_blank">View Trailer</a></p>
                          </div>
                        } */}
                      </div>

                    </div>
                  </div>
                </div>

                <div className="game_review row mt32">
                  <div className="game_review_left col-lg-9">

                    <div className="player_critic_review_box" id="critic_review_box">
                      <div className="review_title">
                        <h3>PlayerCritic Review</h3>
                        {data.totalCriticReview === 0 &&
                          <p className="not_available">N/A</p>
                        }
                      </div>
                      {
                        criticReviews
                          .map(item => {
                            return (
                              <div className="review_box_row mt16">
                                <div className="review_user flex">
                                  <div className="review_user_left mauto">

                                    <div className="img">
                                      <img src={imgCheck(item.user.image, 0, 'user.png')} alt="" />

                                    </div>
                                    <div className="name">
                                      <span>{item.user ? item.user.username : "Deleted User"}</span>
                                    </div>

                                  </div>
                                  <div className="review_user_right mauto">
                                    <span>{item.rating}</span>
                                  </div>

                                </div>

                                {item.attachment &&
                                  <div className="review_img mt16">
                                    <img src={item.attachment} alt="" />
                                  </div>
                                }

                                {item.review &&
                                  <div className="review_content mt16">
                                    <p>{item.review}</p>
                                  </div>

                                }
                                <div className="review_user_right mt8">
                                  <button className="gradient_button small" onClick={() => reviewPopViewHandle(item)}>View score</button>
                                </div>


                              </div>
                            )
                          })
                      }
                    </div>

                    {totalCriticPages > 1 &&
                      <div className="mb32">
                        <Pagination count={totalCriticPages} page={criticCurrentPage} onClick={criticPagination} />
                      </div>
                    }


                    <div className="critic_review_box mt32" id="user_review_box">
                      <div className="review_title">

                        <h3>User Reviews</h3>

                        {data.totalUserReview === 0 &&
                          <p className="not_available">N/A</p>
                        }

                      </div>

                      {
                        userReviews
                          .map(item => {
                            return (
                              <div className="review_box_row mt16">
                                <div className="review_user flex">
                                  <div className="review_user_left mauto">
                                    <div className="img">
                                      <img src={imgCheck(item.user.image, 0, 'user.png')} alt="" />

                                    </div>
                                    <div className="name">
                                      <span>{item.user ? item.user.username : "Deleted User"}</span>
                                    </div>
                                  </div>
                                  <div className="review_user_right mauto">
                                    <span>{item.rating}</span>
                                  </div>

                                </div>

                                {item.attachment &&
                                  <div className="review_img mt16">
                                    <img src={item.attachment} alt="" />
                                  </div>
                                }

                                {item.review &&
                                  <div className="review_content mt16">
                                    <p>{item.review}</p>
                                  </div>
                                }

                                <div className="review_user_right mt8">
                                  <button className="gradient_button small" onClick={() => reviewPopViewHandle(item)}>View score</button>
                                </div>

                              </div>
                            )
                          })
                      }
                    </div>

                    {totalUserPages > 1 &&
                      <div className="mt32">
                        <Pagination count={totalUserPages} page={userCurrentPage} onClick={userPagination} />
                      </div>
                    }


                    <div className="game_review_breakdown mt32">
                      <div className="title flex">
                        <div className="left_title">
                          <h3>Game Review Breakdown</h3>
                        </div>
                        <div className="right_title mauto" >
                          <span>{data.totalReviews} Reviews</span>
                        </div>

                      </div>

                      <div className="game_review_breakdown_slider_wrapper ">
                        {
                          data.overallObj && reviewNames.map((item, i) => {
                            const percentage = nan(Math.round(data.overallObj[reviewKeys[i]] * 10)) + "%"
                            return (
                              <div className="game_review_breakdown_slider m16">
                                <div className="left">
                                  <span>{item}</span>
                                  <div className="progress_box">
                                    <div class="progress-bar-container">
                                      <div class="progress-bar" style={{
                                        width: percentage
                                      }}></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="right">
                                  <span>{percentage}</span>
                                </div>

                              </div>

                            )

                          })
                        }

                      </div>
                      <div className="title flex mt16 ">
                        <div className="left_title">
                          <h3>Average Rating</h3>
                        </div>

                      </div>

                      <div className="avergae_rating flex">

                        <div className="average_rating_box_left">
                          <div className="average_rating_box flex">
                            {
                              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                                <div className="average_rating_box_item" style={{
                                  background: item > data.rating && '#fff'
                                }}>

                                </div>
                              ))
                            }

                          </div>
                        </div>
                        <div className="average_rating_box_right">
                          <span>{score(data.rating)}</span>
                        </div>
                      </div>

                      {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                          const percentage = nan(Math.round((data.ratingPercentage[item] / data.totalReviews) * 100)) + "%"
                          return (
                            <>
                              <div className="avergae_rating_horizontal">
                                <span>{item}</span>
                                <div className="progress_box">
                                  <div class="progress-bar-container">
                                    <div class="progress-bar" style={{
                                      width: percentage
                                    }}></div>
                                  </div>
                                </div>

                                <span>{percentage}</span>
                              </div>
                            </>
                          )
                        })
                      }

                    </div>

                    {(userReviews.length > 0 || criticReviews.length > 0) &&

                      <div className="graph_select_wrapper mt32 mb32">
                        <select name="" id="" onChange={graphHandler}>
                          <option value="Filter By Graph">Filter By Graph</option>
                          <option value="Graph By Day">Graph by Day</option>
                          <option value="Graph By Month">Graph By Month</option>
                          <option value="Graph By Year">Graph By Year</option>
                        </select>
                      </div>
                    }



                    {(graph !== 'Filter By Graph' && graphList.length > 0) &&
                      <div className="row mt32 mb32" id="graph">
                        <Chart graphList={graphList} type={graph} />
                      </div>
                    }

                  </div>


                  {(data.store && data.store.length > 0) &&
                    <div className="game_review_right col-lg-3">
                      <div className="buy_game">
                        <h3 className="buy_game_title">Buy This Game :</h3>
                        <div className="game_box flex">
                          <div className="game_img_left mauto">
                            <img src={data.image} alt="" />
                          </div>
                          <div className="game_img_right mauto">
                            <h3>{data.title}</h3>
                          </div>
                        </div>
                        {/* <div className="game_select_box flex">
                            <span>Platform :</span>
                            <select name="" id="">
                              <option value="">PC</option>
                              <option value="">MAC</option>
                            </select>
                          </div> */}

                      </div>

                      <div className="avialble_platform">
                        {/* <div className="title">
                            <h3>Playstation 4</h3>
                          </div> */}

                        <div className="available_platform_box_wrapper">
                          {
                            data.store.map(item => (
                              <>
                                <div className="available_platform_box">
                                  <div className="available_platform_img text-center">
                                    <img src={item.image} alt="" />
                                  </div>
                                  <Link to={item.link}>
                                    <div className="available_platform_link">
                                      Open <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                        <path d="M14.4498 6.875L14.4498 2.625M14.4498 2.625H10.4831M14.4498 2.625L9.16092 8.29167M7.17758 4.04167H5.72314C4.61237 4.04167 4.05698 4.04167 3.63272 4.27328C3.25954 4.47701 2.95612 4.80209 2.76598 5.20194C2.5498 5.6565 2.5498 6.25156 2.5498 7.44167V11.975C2.5498 13.1651 2.5498 13.7602 2.76598 14.2147C2.95612 14.6146 3.25954 14.9397 3.63272 15.1434C4.05698 15.375 4.61237 15.375 5.72314 15.375H9.95425C11.065 15.375 11.6204 15.375 12.0447 15.1434C12.4179 14.9397 12.7213 14.6146 12.9114 14.2147C13.1276 13.7602 13.1276 13.1651 13.1276 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>

                              </>))
                          }
                        </div>
                      </div>

                    </div>

                  }


                </div>
              </div>

            </div>
          </div>




        </>
      }
      <Footer />


    </>

  );
}

export default SingleGame;
import Game from "../Components/game/game"
import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
import { Icon } from '@iconify/react';
import { useState, useEffect, useRef } from "react"
import { getAll, getOne } from "../api"
import { toast } from "react-toastify"
import { reviewKeys, reviewNames } from "../helpers/utility";
import { Link } from "react-router-dom";
import { nan } from "../helpers/functions";

const SearchResult = ({ rows, onClick, isFound }) => {
  useEffect(() => {
    // below it for search result if click outside 
    const searchResultClick = (e) => {
      if (resultRef && resultRef.current && !resultRef.current.contains(e.target)) {
        setData([])
        setFound(true)
      }
    };
    window.addEventListener("click", searchResultClick);
    return () => window.removeEventListener("click", searchResultClick);
  }, [])

  useEffect(() => {
    setData(rows)
    setFound(isFound)
  }, [rows])
  const [data, setData] = useState([])
  const resultRef = useRef(null)
  const [found, setFound] = useState(true)

  const selectItem = (item) => {
    setData([])
    onClick(item)
  }

  return (
    <div className="search_input">
      {(data.length > 0 || !found) &&
        <div className="search_bar_items">
          <div className="search_result global_scroll" ref={resultRef}>
            {
              data.map(item => {

                return (
                  <div className="search_result_item" onClick={() => selectItem(item)} >


                    <div className="flex search_result_flex">
                      <div className="search_result_img">
                        <img src={item.image} alt="" />
                      </div>

                      <div className="circle_plus">
                        <Icon icon="ei:plus" width="48" height="48" />
                      </div>
                    </div>

                    <div className="search_result_content">
                      <h3>{item.title}</h3>
                      <div className="root_star">

                      </div>
                      {/* <div className="view_product">
                        <Link to={"/dispensary/" + item.url} className="mt16 mb16 gradient_button">View Business</Link>
                      </div> */}
                    </div>
                  </div>
                )
              })
            }

            {!found &&
              <div className="search_result_item">
                <div className="search_result_content">
                  <h3>No results</h3>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}


const GamePage = () => {

  const ITEMS_PER_PAGE = 10;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(false);




  const [compareA, setCompareA] = useState([])
  const [compareB, setCompareB] = useState([])
  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/games/all/compare', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });
      // if (category.length === 0) {
      //   const { data } = await getAll('/news/category/all');

      //   if (data.success) {
      //     setCategory(data.data.category)
      //   }
      // }


      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };



  const [existingSearch, setExistingSearch] = useState('')
  const [existingSearch2, setExistingSearch2] = useState('')
  const [resultRow, setResultRow] = useState([])
  const [resultRow2, setResultRow2] = useState([])

  const [selectSearch, setSelectSearch] = useState()
  const [selectSearch2, setSelectSearch2] = useState()
  const searchSelectHandler = (item) => {
    setExistingSearch('')
    if (selectSearch2) {
      setSelectSearch2('')
      setResultRow2([])
    }
    setSelectSearch(item)
  }


  const searchSelectHandler2 = (item) => {
    setExistingSearch2('')
    setSelectSearch2(item)
  }

  const [timer, setTimer] = useState(null);
  const [timer2, setTimer2] = useState(null);

  const [found, setFound] = useState(true)
  const existingSearchHandler = (e) => {
    const search = e.target.value
    setExistingSearch(search)
    if (selectSearch) {
      setSelectSearch('')
    }
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        if (search.trim()) {
          getAll(`/games/search/compare`, { keyword: search })
            .then(({ data }) => {
              if (data.success) {
                setResultRow(data.data)
                setFound(data.data.length > 0 ? true : false)
              }
            })
        }
      }, 1000)
    )
  }

  const [found2, setFound2] = useState(true)
  const existingSearchHandler2 = (e) => {
    const search = e.target.value
    setExistingSearch2(search)
    if (selectSearch2) {
      setSelectSearch2('')
    }
    if (timer2) {
      clearTimeout(timer2);
      setTimer2(null);
    }

    setTimer2(
      setTimeout(async () => {
        if (search.trim()) {
          getAll(`/games/search/compare`, { keyword: search })
            .then(({ data }) => {
              if (data.success) {
                setResultRow2(data.data)
                setFound2(data.data.length > 0 ? true : false)
              }
            })
        }
      }, 1000)
    )

  }


  const clearInput = () => {
    setExistingSearch('')
    setSelectSearch("")
    setResultRow([])
  }

  const clearInput2 = () => {
    setExistingSearch2('')
    setSelectSearch2("")
    setResultRow2([])
  }


  if (selectSearch && selectSearch2) {
    window.location.href = "#compare_game"
  }
  return (
    <>
      <Header />

      {
        isLoading ?
          <div className="loader"></div>
          :

          <>

            <div className="container">
              <div className="compare_game_wrapper">
                <div className="title mb16">
                  <h1>Compare games</h1>
                </div>
                <div className="compare_game_input">
                  <div className="compare_left">
                    <label htmlFor="">Compare With</label>
                    <input onChange={existingSearchHandler} type="text" name="" id="" placeholder="Enter game title" value={existingSearch} />


                    <SearchResult rows={resultRow} onClick={searchSelectHandler} isFound={found} />

                    {selectSearch &&
                      <div className="selected_product">
                        <div className="search_bar_items">
                          <div className="search_result">
                            <div className="search_result_item">


                              <div className="flex search_result_flex">
                                <div className="search_result_img">
                                  <img src={selectSearch.image} alt="" />
                                </div>

                                <div className="circle_plus">
                                  <Icon icon="ei:minus" width="48" height="48" onClick={() => clearInput()} />
                                </div>
                              </div>


                              <div className="search_result_content">
                                <h3>{selectSearch.title}</h3>

                                {/* <div className="view_product">
                                  <Link to={"/games/" + selectSearch.url} className="result_anchor">View Game</Link>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                  </div>
                  <div className="compare_right mt16">
                    <label htmlFor="">Compare With</label>
                    <input type="text" name="" id="" placeholder="Enter game title" onChange={existingSearchHandler2} value={existingSearch2} />


                    <SearchResult rows={resultRow2} onClick={searchSelectHandler2} isFound={found2} />

                    {selectSearch2 &&
                      <div className="selected_product">
                        <div className="search_bar_items">
                          <div className="search_result">
                            <div className="search_result_item">


                              <div className="flex search_result_flex">
                                <div className="search_result_img">
                                  <img src={selectSearch2.image} alt="" />
                                </div>

                                <div className="circle_plus">
                                  <Icon icon="ei:minus" width="48" height="48" onClick={() => clearInput2()} />
                                </div>
                              </div>


                              <div className="search_result_content">
                                <h3>{selectSearch2.title}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                  </div>

                </div>

                {(selectSearch && selectSearch2) &&
                  <div className="admin_table table-responsive mt16 mb32">

                    <table className="table">


                      <tbody id="compare_game">

                        <tr>
                          <td>Name</td>
                          <td>{selectSearch.title}</td>
                          <td>{selectSearch2.title}</td>
                        </tr>
                        <tr>
                          <td>Total Reviews</td>
                          <td>{selectSearch.totalReviews}</td>
                          <td>{selectSearch2.totalReviews}</td>
                        </tr>

                        <tr>
                          <td>User Rating</td>
                          <td>{selectSearch.userRating}</td>
                          <td>{selectSearch2.userRating}</td>
                        </tr>
                        <tr>
                          <td>Critic Rating</td>
                          <td>{selectSearch.criticRating}</td>
                          <td>{selectSearch2.criticRating}</td>
                        </tr>

                        <tr>
                          <td>Overall Rating</td>
                          <td>{selectSearch.rating}</td>
                          <td>{selectSearch2.rating}</td>
                        </tr>



                        {

                          selectSearch.overallObj && reviewNames.map((item, i) => {
                            const percentage = nan(Math.round((selectSearch.overallObj[reviewKeys[i]] / selectSearch.totalReviews) * 10)) + "%"

                            const percentage2 = nan(Math.round((selectSearch2.overallObj[reviewKeys[i]] / selectSearch2.totalReviews) * 10)) + "%"


                            return (
                              <>

                                <tr>
                                  <td>{item}</td>
                                  <td>{percentage}</td>
                                  <td>{percentage2}</td>
                                </tr>
                              </>

                            )
                          })

                        }

                        <tr>
                          <td>Platform</td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch.platform.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>

                          </td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch2.platform.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Genre</td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch.genre.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>

                          </td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch2.genre.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Feature</td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch.feature.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>

                          </td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch2.feature.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Feature</td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch.feature.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>

                          </td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch2.feature.map(item => (
                                  <span>{item.label}</span>
                                ))
                              }
                            </div>
                          </td>
                        </tr>

                        {(selectSearch.brand && selectSearch2.brand && selectSearch.brand.length > 0 && selectSearch2.brand.length > 0) &&
                          <tr>
                            <td>Brand</td>
                            <td>
                              {selectSearch.brand[0].title}
                            </td>
                            <td>
                              {selectSearch2.brand[0].title}
                            </td>
                          </tr>
                        }

                        <tr>
                          <td>Available store</td>
                          <td className="">
                            <div className="compare_loop_item">
                              {

                                selectSearch.store.map(item => (
                                  <span>{item.title}</span>
                                ))
                              }
                            </div>
                          </td>
                          <td className="">
                            <div className="compare_loop_item">
                              {
                                selectSearch2.store.map(item => (
                                  <span>{item.title}</span>
                                ))
                              }
                            </div>
                          </td>
                        </tr>



                      </tbody>
                    </table>



                  </div>

                }
              </div>
            </div>



          </>
      }


      <Footer />
    </>
  )
}

export default GamePage
import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND + "/api", // Update the base URL to match your backend API address
});
// Get the token from localStorage
var token = localStorage.getItem("playercritic_user_Token");

// Add the Authorization header to the Axios instance if the token exists
if (token) {
  api.defaults.headers.common["Authorization"] = `${token.replace(
    /^"|"$/g,
    ""
  )}`;
}

// Game APIs
export function createGame(gameData) {
  return api.post("/games", gameData);
}

export const getGameByFriendlyUrl = async (friendlyUrl) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/${friendlyUrl}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export function getGameById(gameId) {
  return api.get(`/games/${gameId}`);
}
export function addGameView(gameId) {
  return api.get(`/games/count/${gameId}`);
}
export function getPopularGames(gameId) {
  return api.get(`/games/popular/${gameId}`);
}

export function updateGame(gameId, gameData) {
  return api.put(`/games/${gameId}`, gameData);
}
export function randomGame() {
  return api.post(`/games/random`);
}
export function deleteGame(GameData) {
  return api.post("/games/delete", GameData);
}
export function blockGame(GameData) {
  return api.post("/games/block", GameData);
}
export function unBlockGame(GameData) {
  return api.post("/games/un-block", GameData);
}
export function approveGame(GameData) {
  return api.post("/games/approve", GameData);
}
export function rejectGame(GameData) {
  return api.post("/games/reject", GameData);
}
export function getAllGames(gameData) {
  return api.post("/games/all", gameData);
}
export function searchGameByUser(gameData) {
  return api.post("/games/mygames", gameData);
}
export function searchGame(gameData) {
  return api.post("/games/search", gameData);
}
export function trendingToday(gameData) {
  return api.post("/games/trending", gameData);
}
export function getPendingGames(gameData) {
  return api.post("/games/pending", gameData);
}

// User APIs
export function registerUser(userData) {
  return api.post("/users/register", userData);
}

export function loginUser(userData) {
  return api.post("/users/login", userData);
}
export function loginAdmin(userData) {
  return api.post("/users/adminlogin", userData);
}

export function getUserProfile() {
  return api.get("/users/profile");
}
export function getAllUserProfiles(userData) {
  return api.post("/users/all", userData);
}

export function updateUserProfile(userData) {
  return api.put("/users/profile", userData);
}
export function updateUserPassword(userData) {
  return api.put("/users/password", userData);
}

export function deleteUser(userData) {
  return api.post("/users/delete", userData);
}
export function blockUser(userData) {
  return api.post("/users/block", userData);
}
export function unBlockUser(userData) {
  return api.post("/users/un-block", userData);
}

export function generateAffiliateLink() {
  return api.get("/users/affiliate/link");
}

export function getAffiliateDashboard() {
  return api.get("/users/affiliate/dashboard");
}

// Review APIs
export function createReview(reviewData) {
  return api.post("/reviews", reviewData);
}
export function getReviews() {
  return api.get("/reviews");
}
export function getReview(id) {
  return api.get("/reviews/" + id);
}
export function getReviewById(reviewId) {
  return api.get(`/reviews/${reviewId}`);
}
export function updateReview(reviewId, reviewData) {
  return api.put(`/reviews/${reviewId}`, reviewData);
}
export function getReviewsByGameId(gameId) {
  return api.get(`/reviews/game/${gameId}`);
}

// Below this function is made by Saif 

export function getAll(url, data = {}) {
  return api.post(url, data);
}
export function getOne(url) {
  return api.get(`${url}`);
}

export function updateOne(url, data) {
  return api.put(`${url}`, data);
}

export function deleteOne(url) {
  return api.delete(`${url}`);
}
export function createOne(url, data) {
  return api.post(`${url}`, data);
}

export function fileUpload(url, file, type = 'global') {
  const formData = new FormData(); // Create a new FormData object
  formData.append("file", file);
  formData.append("type", type);
  return api.post(`${url}`, formData);
}
export function fileUploadMulti(url, file, type = 'global') {
  const formData = new FormData(); // Create a new FormData object
  file.map(item => {
    console.log(item)
    formData.append("file", item);
  })
  // formData.append("type", type);
  return api.post(`${url}`, formData);
}

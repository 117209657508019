import { useState, useRef, useEffect } from "react"
import { updateOne, deleteUser, deleteOne, createOne } from "../../../api";
import { toast } from "react-toastify";
import { Icon } from '@iconify/react';

const ReviewPopup = ({ close, isPopup, popupType, item, type }) => {


  const [isProcessing, setisProcessing] = useState(false);
  const [isEmail, setIsEmail] = useState(false)
  const [isUsername, setIsUsername] = useState(false)
  const [value, setValue] = useState({

  })
  const [oldValue, setOldValue] = useState({

  })

  useEffect(() => {
    if (popupType !== 'add') {
      setValue(item)
      setOldValue(item)
    }
    if (popupType === 'add') {
      setValue({
        username: "",
        email: "",
        password: "",
        type: "",
      })
      setOldValue({
        username: "",
        email: "",
        password: "",
        type: "",
      })
    }
  }, [item])

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }


  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }




  const addUser = async (e) => {
    e.preventDefault()
    setisProcessing(true);
    try {
      const { data } = await createOne("/users/register/admin", {
        username: value.username,
        email: value.email,
        password: value.password,
        type: type
      });

      if (data.success) {
        toast.success("A New User is Added Successfully");
        close(true)
      }
      if (data.isUsername || data.isEmail) {
        setIsUsername(data.isUsername)
        toast.error("This username is used by someone else");
      }
      if (data.isEmail) {
        setIsEmail(data.isEmail)
        toast.error("This email is used by someone else");
      }
      setValue({
        username: "",
        email: "",
        password: "",
      })
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response.data.error || "Error updating user information"
      );
    } finally {
      setisProcessing(false);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault()
    setisProcessing(true);
    try {
      const { data } = await updateOne("/users/all/update", {
        userName: value.username,
        email: value.email,
        oldEmail: oldValue.email,
        oldUserName: oldValue.username,
        userId: value._id,
        status: value.status
      });

      if (data.success) {
        toast.success("User Information Updated");
        close(true)
      }
      if (data.isUsername || data.isEmail) {
        setIsUsername(data.isUsername)
        toast.error("This username is used by someone else");
      }
      if (data.isEmail) {
        setIsEmail(data.isEmail)
        toast.error("This email is used by someone else");
      }
      // else {
      //   toast.error(data.error || "Error updating user information");
      // }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response.data.error || "Error updating user information"
      );
    } finally {
      setisProcessing(false);
    }
  };


  const handleDeleteUser = async () => {
    try {
      var res = await deleteOne("/users/delete/" + value._id);
      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };

  const handleBlock = async () => {
    try {
      const res = await updateOne("/users/block", {
        id: value._id
      });

      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };

  const handleunBlock = async () => {
    try {
      const res = await updateOne("/users/un-block", {
        id: value._id
      });

      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };


  const [eye, setEye] = useState(false)
  const passwordHandler = () => {
    setEye(!eye)
  }


  switch (popupType) {


    case 'add':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {
              isProcessing ? <div className="loader"></div>

                :
                <div className="edit_box">

                  <form onSubmit={addUser} autoComplete="off">
                    <div className="input_items">
                      <div className="input_item">
                        <label htmlFor="">Email</label>
                        <input placeholder="Enter your email" onChange={onChange} type="email" name="email" value={value.email} autoComplete="off" autoSave="off" />
                      </div>
                      <div className="input_item">
                        <label htmlFor="">Username</label>
                        <input placeholder="Enter your username" onChange={onChange} type="text" name="username" value={value.username} autoComplete="off" autoSave="off" />
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Password</label>

                        <div className="password_icon_wrapper">
                          <Icon onClick={passwordHandler} icon="mdi:eye" width="24" height="24" />
                          <input placeholder="Enter your password" onChange={onChange} type={eye ? "text" : "password"} name="password" value={value.password} autoComplete="off" autoSave="off" />
                        </div>

                      </div>

                    </div>

                    <div className="remove_box">
                      <div className="button_item">
                        <button type="submit" className="delete gradient_button">Add</button>
                        <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
            }

          </div>
        </div>



      )
    case 'edit':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {
              isProcessing ? <div className="loader"></div>

                :
                <div className="edit_box">

                  <form onSubmit={updateUser}>
                    <div className="input_items">
                      <div className="input_item">
                        <label htmlFor="">Email</label>
                        <input placeholder="Enter your email" onChange={onChange} type="email" name="email" value={value.email} />
                      </div>
                      <div className="input_item">
                        <label htmlFor="">Username</label>
                        <input placeholder="Enter your username" onChange={onChange} type="text" name="username" value={value.username} />
                      </div>
                      <div className="input_item">
                        <label htmlFor="">Status</label>

                        <select name="status" id="" onChange={onChange}>
                          <option value="">Select Status</option>
                          <option selected={value.status === "Approved"} value="Approved">Approved</option>
                          <option selected={value.status === "Block"} value="Block">Block</option>
                        </select>
                      </div>
                    </div>

                    <div className="remove_box">
                      <div className="button_item">
                        <button type="submit" className="delete gradient_button">Update</button>
                        <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>


            }

          </div>
        </div>



      )

    case 'view':
      return (

        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="view_data admin_table">
              <table className="">
                <tbody>
                  <tr>
                    <td>Email:</td>
                    <td>{item.email}</td>
                  </tr>

                  <tr>
                    <td>Username:</td>
                    <td>{item.username}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{item.status}</td>
                  </tr>
                  <tr>
                    <td>User Type:</td>
                    <td>{item.type}</td>
                  </tr>
                  <tr>
                    <td>Last Login:</td>
                    <td>{new Date(item.joinedOn).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>


              <div className="remove_box mt16">
                <div className="button_item">
                  <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      )

    case 'delete':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to delete it?</h2>
              <div className="button_item">
                <button className="delete gradient_button" onClick={handleDeleteUser}>Delete</button>
                <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )

    case 'block':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to block this user?</h2>
              <div className="button_item">
                <button onClick={handleBlock} className="delete gradient_button">Block</button>
                <button onClick={() => close(false)} className="cancel gradient_button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
    case 'unblock':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to unblock this user?</h2>
              <div className="button_item">
                <button onClick={handleunBlock} className="delete gradient_button">Unblock</button>
                <button onClick={() => close(false)} className="cancel gradient_button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
  }

}

export default ReviewPopup
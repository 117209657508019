import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import Login from "../Components/login/login";
export default function LoginPage() {


  return (
    <>
      <Header />
      <Login />
      <Footer />
    </>
  );
}


import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="container">

        <div className="row privacy_row">
          <div className="col privacy_col">
            <div className="privacy_title">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M10.3773 5.07847C12.6111 3.75787 15.2171 3 18 3C26.2843 3 33 9.71573 33 18C33 18.0497 32.9998 18.0993 32.9993 18.1489M5.07171 10.3888C3.75528 12.62 3 15.2218 3 18C3 24.9894 7.7804 30.8623 14.25 32.5274M31.0615 25.3809C29.0808 28.8783 25.736 31.5015 21.75 32.5274M21.1339 9.56066C20.158 9.19811 19.1021 9 18 9C13.0294 9 9 13.0294 9 18C9 19.1081 9.20027 20.1695 9.56658 21.1498M26.4376 14.8615C26.8013 15.8387 27 16.8961 27 18C27 22.9706 22.9706 27 18 27C16.9052 27 15.8561 26.8045 14.8856 26.4466M18 15V21" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h1>Privacy Policy</h1>
            </div>

            <div className="privacy_item">
              <h2>Introduction</h2>
              <p>This Privacy Policy outlines how playercritic.com collects, uses, and protects your personal information when you visit our website and interact with our services. Your privacy is important to us, and we are committed to protecting your personal data.</p>
            </div>
            <div className="privacy_item">
              <h2>Information We Collect</h2>
              <ul>
                <li>Personal Information: When you interact with our website, we may collect personal information such as your name, email address, IP address, and any other information you voluntarily provide.  </li>
                <li>Usage Data: We may collect information about how you use our website, including your browsing history, preferences, and interactions with our content.</li>
                <li>Cookie Information: We use cookies and similar tracking technologies to collect information about your usage patterns and preferences.</li>
              </ul>

            </div>
            <div className="privacy_item">
              <h2>Information We Collect</h2>
              <ul>
                <li>To Provide Our Services: We use your information to provide and improve our gaming review services, personalize your experience, and communicate with you.</li>
                <li>For Marketing and Communication: We may use your information to send you marketing communications, such as newsletters, promotions, and updates about our services.</li>
                <li>To Analyze and Improve Our Website: We use your information to analyze how our website is used, identify trends, and make improvements.</li>
                <li>To Protect Our Website and Users: We may use your information to protect our website and users from unauthorized access, fraud, or other security threats.</li>
              </ul>
            </div>
            <div className="privacy_item">
              <h2>Cookie Policy</h2>
              <p>We use cookies and similar tracking technologies to improve your browsing experience and collect information about how you use our website.
              </p>
              <p>You can manage your cookie preferences through your browser settings.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import Profile from "../Components/profile/profile";

const ProfilePage = () => {
  return (
    <>
      <Header />
      <Profile />
      <Footer />
    </>
  )
}

export default ProfilePage
import { useState, useEffect, useRef } from "react"
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { reviewKeys, reviewKeysName } from "../../helpers/utility";
import Game from "../game/game"
import { getAll } from "../../api"

const ratingValues = {
  'gameplay': [1, 10],
  'story': [1, 10],
  'visuals': [1, 10],
  'sound': [1, 10],
  'controls': [1, 10],
  'replayability': [1, 10],
  'originality': [1, 10],
  'value': [1, 10],
  'technicalPerformance': [1, 10],
  'accessibility': [1, 10],
  'difficultyLevel': [1, 10],
  'hourlyPlayed': [1, 10]
}

const Plus = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M7.99968 5.33301V10.6663M5.33301 7.99968H10.6663M14.6663 7.99968C14.6663 11.6816 11.6816 14.6663 7.99968 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99968C1.33301 4.31778 4.31778 1.33301 7.99968 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99968Z" stroke="white" stroke-opacity="0.7" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
</svg>



function Search() {

  const [filter, setFilter] = useState("Ratings")
  const min = 1
  const max = 10

  const [tools, setTools] = useState({
    genre: [],
    brand: [],
    platform: [],
    store: [],
  })
  useEffect(() => {
    getAll('/games/tools')
      .then(({ data }) => {
        setTools(data.data)
      })
  }, []);



  const [filtemenu, setFiltermenu] = useState(false)
  const filterMenuHandler = () => {
    setFiltermenu(!filtemenu)
  }


  const filterChangeHandler = (item) => {
    setFilter(item)
  }


  const [filterRating, setFilterRating] = useState(ratingValues)
  const ratingSliderInput = (price, key) => {
    setFilterRating({ ...filterRating, [key]: price })
  }

  const ratingInputHandler = (e, key, index) => {
    let copyRating = { ...filterRating }
    copyRating[key][index] = parseInt(e.target.value)
    setFilterRating(copyRating)
  }

  const [genre, setGenre] = useState({})
  const genreHandler = (e, key, index) => {

    let copyGenre = { ...genre }
    if (copyGenre[e.target.name]) {
      delete copyGenre[e.target.name]
    } else {
      copyGenre[e.target.name] = true
    }
    setGenre(copyGenre)

  }

  const [platform, setPlatform] = useState({})
  const platformHandler = (e, key, index) => {
    let copyPlatform = { ...platform }
    if (copyPlatform[e.target.name]) {
      delete copyPlatform[e.target.name]
    } else {
      copyPlatform[e.target.name] = true
    }
    setPlatform(copyPlatform)
  }

  const [store, setStore] = useState({})

  const storeHandler = (e, key, index) => {
    let copyStore = { ...store }
    if (copyStore[e.target.name]) {
      delete copyStore[e.target.name]
    } else {
      copyStore[e.target.name] = true
    }
    setStore(copyStore)
  }

  const [keyword, setKeyword] = useState('')

  const clearFilter = () => {
    if (filter === 'Ratings') setFilterRating(ratingValues)
    if (filter === 'Genres') setGenre({})
    if (filter === 'Platforms') setPlatform({})

  }


  const [data, setData] = useState([])
  const [notfound, setNotfound] = useState(false)
  const [filterIsLoading, setFilterIsLoading] = useState(false);

  const applyFilter = () => {
    setFilterIsLoading(true)
    setFiltermenu(false)

    let searchData

    if (filter == 'Ratings') searchData = filterRating
    if (filter == 'Genres') searchData = genre
    if (filter == 'Platforms') searchData = platform
    if (filter == 'Stores') searchData = store


    getAll('/games/search', { value: searchData, type: filter, keyword: keyword.trim() })
      .then(({ data }) => {

        if (data.success) {
          setData(data.data)
          if (data.data.length === 0) setNotfound(true)
        }

        setFilterIsLoading(false)

      })
  }

  const gameFinder = () => {
    const search = keyword.trim()
    if (search) {
      setFilterIsLoading(true)
      getAll('/games/search', { keyword: search })
        .then(({ data }) => {

          if (data.success) {
            setData(data.data)
            if (data.data.length === 0) setNotfound(true)
          }
          setFilterIsLoading(false)
        })
    }
  }



  return (
    <>
      <div className="container">
        <div className="search_bar">
          <div className="flex">
            <div className="input_left flex">
              <div className="left">
                <input type="text" placeholder='Search' onChange={(e) => setKeyword(e.target.value)} value={keyword} />
              </div>
              <div className="right">
                <div className="right_filter" onClick={filterMenuHandler}>
                  <img src="/Command Icon.png" alt="" />
                  <span>F</span>
                </div>

              </div>

            </div>
            <div className="input_right">
              <button className='gradient_button' onClick={gameFinder}>Gamefinder</button>
            </div>
          </div>


          <div className="search_pop_wrapper" style={{
            display: filtemenu ? 'block' : 'none'
          }}>
            <div className="search_pop">
              <div className="close_icon">
                <svg onClick={() => setFiltermenu(false)} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                  <path d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" fill="#08090B" />
                  <path d="M22.5 13.5L13.5 22.5M13.5 13.5L22.5 22.5M33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              <div className="search_pop_heading">
                <p>What game you’re looking for ?</p>
              </div>

              <div className="search_filter_wrapper">
                <div className="left">
                  <div className="search_pop_filter">
                    {
                      ['Ratings', 'Genres', 'Platforms', 'Stores'].
                        map(item => (
                          <div className={"filter_item " + `${item === filter && 'active'}`} onClick={() => filterChangeHandler(item)}>
                            <Plus />
                            <span>{item}</span>
                          </div>
                        ))
                    }
                  </div>
                </div>
                {/* <div className="right">
                <p>Clear Filters</p>
              </div> */}
              </div>

              {filter == "Ratings" &&
                <div className={`"pop_option_wrapper active"`}>
                  <div className="pop_option_main">
                    <div className="pop_heading flex">
                      <div className="left">
                        <p>Filter by Ratings</p>
                      </div>
                      <div className="right">
                        <button className='apply' onClick={applyFilter}>Apply Filters</button>
                        <button className='clear' onClick={clearFilter}>Clear All</button>
                      </div>
                    </div>
                    <div className="pop_slider flex">


                      {
                        reviewKeys.map((item, i) => (

                          <div className="slider_input_main">
                            <div className="slider_heading">
                              <p>{reviewKeysName[i]}</p>
                            </div>

                            <div className="slider_input_wrapper">

                              <div className="slider_input flex">
                                <div className="left">
                                  <input type="number" className='number' placeholder='Min'
                                    value={filterRating[item][0]} min={1} max={10} onChange={(e) => ratingInputHandler(e, item, 0)} />
                                </div>
                                <div className="middle text-center">
                                  <p>To</p>
                                </div>
                                <div className="right">
                                  <input type="number" className='number' placeholder='Max' value={filterRating[item][1]} min={1} max={10} onChange={(e) => ratingInputHandler(e, item, 1)} />
                                </div>
                              </div>

                              <div className="range_slider_wrapper">
                                <RangeSlider rangeSlideDisabled={true} step={1} onInput={(e) => ratingSliderInput(e, item)} max={max} min={min} value={filterRating[item]} />

                              </div>



                            </div>

                          </div>
                        ))
                      }
                    </div>

                  </div>
                </div>
              }


              {filter == "Genres" &&
                <div className={`"pop_option_wrapper active"`}>
                  <div className="pop_option_main">
                    <div className="pop_heading flex">
                      <div className="left">
                        <p>Filter by Genres</p>
                      </div>
                      <div className="right">
                        <button className='apply' onClick={applyFilter}>Apply Filters</button>
                        <button className='clear' onClick={clearFilter}>Clear All</button>
                      </div>
                    </div>
                    <div className="pop_slider flex">

                      <div className="checkbox_wrapper">
                        {
                          tools.genre.map(item => (
                            <div className="checkbox_item flex">
                              <input type="checkbox" name={item} id={item} onChange={(e) => genreHandler(e, item)} checked={genre[item] ? true : false} />
                              <label htmlFor={item}>{item}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              }

              {filter == "Platforms" &&
                <div className={`"pop_option_wrapper active"`}>
                  <div className="pop_option_main">
                    <div className="pop_heading flex">
                      <div className="left">
                        <p>Filter by Platforms</p>
                      </div>
                      <div className="right">
                        <button className='apply' onClick={applyFilter}>Apply Filters</button>
                        <button className='clear' onClick={clearFilter}>Clear All</button>
                      </div>
                    </div>
                    <div className="pop_slider flex">

                      <div className="checkbox_wrapper">
                        {
                          tools.platform.map(item => (
                            <div className="checkbox_item flex">
                              <input type="checkbox" name={item} id={item} onChange={(e) => platformHandler(e, item)} checked={platform[item] ? true : false} />
                              <label htmlFor={item}>{item}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              }

              {filter == "Stores" &&
                <div className={`"pop_option_wrapper active"`}>
                  <div className="pop_option_main">
                    <div className="pop_heading flex">
                      <div className="left">
                        <p>Filter by Stores</p>
                      </div>
                      <div className="right">
                        <button className='apply' onClick={applyFilter}>Apply Filters</button>
                        <button className='clear' onClick={clearFilter}>Clear All</button>
                      </div>
                    </div>
                    <div className="pop_slider flex">

                      <div className="checkbox_wrapper">
                        {
                          tools.store.map(item => (
                            <div className="checkbox_item flex">
                              <input type="checkbox" name={item.title} id={item.title} onChange={(e) => storeHandler(e, item.title)} checked={store[item.title] ? true : false} />
                              <label htmlFor={item.title}>{item.title}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              }


            </div>
          </div>

        </div>
      </div>


      {filterIsLoading ?
        <div className="loader"></div>

        :

        data.length > 0 ?

          <>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2 className="notfound mb16">Search result:</h2>
                </div>
              </div>
            </div>

            <Game data={data} />

          </>


          :

          (notfound && data.length === 0) &&
          <h2 className="notfound mb32">No result is found</h2>


      }

    </>
  );
}

export default Search;
import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import CarouselAll from "../carousel/carousel"
import { SplideSlide } from "@splidejs/react-splide"
import RangeSlider from 'react-range-slider-input';
import { Icon } from '@iconify/react';
import { getAll, updateOne } from "../../api";
import { toast } from "react-toastify";
import { reviewNames, reviewKeys } from "../../helpers/utility";
import UserContext from "../../Context/UserContext";
import { score } from "../../helpers/functions"
import { imgCheck } from "../../helpers/functions";

const Game = ({ type, data = [], tools, favourite = function favourite() { }, currentUrl }) => {

  const [user, setUser] = useContext(UserContext)

  const userId = user.userId

  const [favLoad, setFavload] = useState({})
  const favouriteHandler = (index, id, type) => {
    if (userId) {
      setFavload({ ...favLoad, [index]: true })
      updateOne('/games/favourite', { id: id, type: type })
        .then((res) => {
          let copy = [...data]
          if (type === 'plus')
            copy[index].likes = [...copy[index].likes, userId]

          if (type === 'minus')
            copy[index].likes = copy[index].likes.filter(item => item !== userId)
          // favourite(copy)
          setFavload({ ...favLoad, [index]: false })
        })
    } else {
      setUser({ ...user, loginPopup: true })
    }

  }


  return (
    <>

      <div className="container">

        <>
          {type === 'slide' ? <>

            <div className="game_row row">
              <CarouselAll className={"game_row"} page={3}>
                {
                  data.map((item, i) => (
                    <SplideSlide>

                      <div className="game_wrapper">




                        <div className="game_img">
                          <img src={imgCheck(item.image, 0, 'game.png')} alt="" />
                          {item.rating > 0 &&
                            <span className="game_review">{score(item.rating)}</span>
                          }
                        </div>

                        <div className="game_title_wrapper">
                          <h3 className="game_title">{item.title}</h3>
                        </div>


                        <div className="game_description">


                          <div className="game_category">

                            {/* {item.brand.length > 0 &&
                              <div className="left">
                                <img src={item.brand[0].image} alt="" />
                                <span>{item.brand[0].title}</span>
                              </div>
                            } */}
                            <div className="right flex-grow">
                              <div className="circle_plus">

                                {

                                  favLoad[i] ? <div className="loader_circle"></div>

                                    :


                                    !item.likes.includes(userId) ? <Icon icon="ei:plus" width="36" height="36" onClick={() => favouriteHandler(i, item._id, 'plus')} />

                                      :
                                      <Icon icon="ei:minus" width="36" height="36" onClick={() => favouriteHandler(i, item._id, 'minus')} />

                                }

                              </div>
                            </div>

                            <div className="open_link mauto">
                              <Link to={"/games/" + item.url}><span>Open</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                  <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Link>
                            </div>
                          </div>

                          {/* <div className="game_users">
                            <div className="left">
                              <div className="img_circle">
                                {
                                  [1, 2, 3].map(item => (
                                    <div className="img_box">
                                      <img src="/user.png" alt="" />
                                    </div>
                                  ))
                                }


                              </div>

                              <div className="user_right">
                                <p className="player">{item.totalReviews} players</p>
                                <p className="rating">Rating this game</p>
                              </div>

                            </div>

                            <div className="right">
                              <Link to={"/games/" + item.url}><span>Open</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                  <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Link>
                            </div>

                            
                          </div> */}
                        </div>
                      </div>

                    </SplideSlide>
                  ))
                }
              </CarouselAll>
            </div>

            <div className="browse_row row ">
              <Link to={"/games/" + currentUrl}>
                <div className="col flex">
                  <h3>Browse all</h3>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </Link>
            </div>
          </>

            :
            <>


              <div className="game_row row non_slide">

                {data.map((item, i) => (

                  <>


                    <div className="game_col col-lg-4 col-md-6">
                      <div className="game_wrapper">
                        <div className="game_img_title_wrapper">
                          <div className="game_img">
                            <img src={imgCheck(item.image, 0, 'game.png')} alt="" />
                            {item.rating > 0 &&
                              <span className="game_review">{score(item.rating)}</span>
                            }
                          </div>

                          <div className="game_title_wrapper">
                            <h3 className="game_title">{item.title}</h3>
                          </div>
                        </div>
                        <div className="game_description">

                          <div className="game_category">

                            {/* {item.brand.length > 0 &&
  <div className="left">
    <img src={item.brand[0].image} alt="" />
    <span>{item.brand[0].title}</span>
  </div>
} */}
                            <div className="right flex-grow">
                              <div className="circle_plus">

                                {

                                  favLoad[i] ? <div className="loader_circle"></div>

                                    :


                                    !item.likes.includes(userId) ? <Icon icon="ei:plus" width="36" height="36" onClick={() => favouriteHandler(i, item._id, 'plus')} />

                                      :
                                      <Icon icon="ei:minus" width="36" height="36" onClick={() => favouriteHandler(i, item._id, 'minus')} />

                                }

                              </div>
                            </div>

                            <div className="open_link mauto">
                              <Link to={"/games/" + item.url}><span>Open</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                  <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Link>
                            </div>
                          </div>



                          {/* <div className="game_users">
                            <div className="left">
                              <div className="img_circle">
                                {
                                  [1, 2, 3].map(item => (
                                    <div className="img_box">
                                      <img src="/user.png" alt="" />
                                    </div>
                                  ))
                                }


                              </div>

                              <div className="user_right">
                                <p className="player">{item.totalReviews} players</p>
                                <p className="rating">Rating this game</p>
                              </div>

                            </div>

                            <div className="right">
                              <Link to={"/games/" + item.url}><span>Open</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                  <path d="M14.0172 6.875L14.0172 2.625M14.0172 2.625H10.0505M14.0172 2.625L8.7283 8.29167M6.74497 4.04167H5.29052C4.17975 4.04167 3.62436 4.04167 3.20011 4.27328C2.82692 4.47701 2.52351 4.80209 2.33336 5.20194C2.11719 5.6565 2.11719 6.25156 2.11719 7.44167V11.975C2.11719 13.1651 2.11719 13.7602 2.33336 14.2147C2.52351 14.6146 2.82692 14.9397 3.20011 15.1434C3.62436 15.375 4.17975 15.375 5.29052 15.375H9.52163C10.6324 15.375 11.1878 15.375 11.612 15.1434C11.9852 14.9397 12.2886 14.6146 12.4788 14.2147C12.695 13.7602 12.695 13.1651 12.695 11.975V10.4167" stroke="white" stroke-width="1.32222" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Link>
                            </div>
                          </div> */}


                        </div>

                      </div>
                    </div>


                  </>
                ))}

              </div>


            </>
          }
        </>

      </div>
    </>
  )
}

export default Game
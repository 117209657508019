import { useState } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import Progress from '../progress/progress'
import { SplideSlide } from "@splidejs/react-splide"
import { reviewKeys, reviewNames } from "../../helpers/utility";
import CarouselAll from '../carousel/carousel';
import { nan, substring, score, imgCheck } from '../../helpers/functions';

const Slider = ({ data = [] }) => {


  return (
    <>

      <div className="container">

        <CarouselAll className={"home_slider"} page={1} pagination={true} arrows={false}
          options={{ perPgae: 1 }}>
          {
            data.map(item => (
              <>
                <SplideSlide>
                  <div className="slider" style={{
                    backgroundImage: `url("${imgCheck(item.cover, 0, 'game-banner.png')}")`
                  }}>
                    <div className="slider_overlay">
                      <div className="left_slide">
                        <div className="content_box content_box_1" >
                          <h2>{item.title}</h2>
                          <p>{substring(item.description, 150)}</p>
                          <Link className="add_review_btn" target='_blank' to={"/games/" + item.url}>Add a Review </Link>
                        </div>
                        <div className="content_box content_box_2">

                          <div className="circle_box">
                            <div className="circle_left">
                              <Progress value={score(item.rating)} />
                            </div>
                            <div className="circle_right">

                              <div className="progress_box">
                                <p>{score(item.overallObj.gameplay)} Game Play</p>
                                <div class="progress-bar-container">
                                  <div class="progress-bar"
                                    style={{
                                      width: nan(item.overallObj.gameplay * 10) + "%"
                                    }}></div>
                                </div>
                              </div>


                              <div className="progress_box">
                                <p>{score(item.overallObj.value)} Value</p>
                                <div class="progress-bar-container">
                                  <div class="progress-bar" style={{
                                    width: nan(item.overallObj.value * 10) + "%"
                                  }}></div>
                                </div>
                              </div>


                              <div className="progress_box">
                                <p>{score(item.overallObj.technicalPerformance)} Technical Performance</p>
                                <div class="progress-bar-container">
                                  <div class="progress-bar" style={{
                                    width: nan(item.overallObj.technicalPerformance * 10) + "%"
                                  }}></div>
                                </div>
                              </div>



                            </div>
                          </div>
                        </div>



                      </div>
                      {/* <div className="right_slide">
                      
                      <img src={item.image} alt="" />
                    </div> */}
                    </div>


                  </div>

                </SplideSlide>
              </>
            )
            )
          }
        </CarouselAll>

      </div>
    </>
  )
}

export default Slider
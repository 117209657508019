import Game from "../Components/game/game"
import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
import Pagination from "../Components/pagination/pagination"
import { useState, useEffect, useRef, useContext } from "react"
import { getAll, getOne } from "../api"
import { toast } from "react-toastify"

import LoginPopup from '../Components/popup/login-popup'
import UserContext from "../Context/UserContext"
const GamePage = ({ type, title }) => {
  const [user, setUser] = useContext(UserContext)
  const ITEMS_PER_PAGE = 10;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [filterIsLoading, setFilterIsLoading] = useState(false);


  useEffect(() => {
    if (!keyword) {
      getData();
    } else {
      paginationData(currentPage)
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, type]);


  const [tools, setTools] = useState()


  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/games/my-game', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: type
      });

      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const paginationData = async (currentPage) => {
    setCurrentPage(currentPage)
    setIsLoading(true);
    try {
      const res = await getAll('/admin/search', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: "news",
        keyword: keyword
      });
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  const pagination = (item) => {
    setCurrentPage(item)
  }


  const filterSearch = (filterValue) => {
    setFilterIsLoading(true)
    getAll('/games/search', filterValue)
      .then(({ data }) => {

        if (data.success) {
          setData(data.data)
        }
        setFilterIsLoading(false)

      })
  }


  const favourite = (data) => {
    setData(data)
  }



  const [popup, setPopup] = useState(false)


  const popupcloseHandler = (isOpen, data, review) => {
    setPopup(false)


  }

  return (
    <>
      <Header />
      {user.loginPopup &&
        <LoginPopup close={popupcloseHandler} gameData={data} />
      }

      {
        isLoading ?
          <div className="loader"></div>
          :

          <>

            <div className="container">

              <div className="reviews_box flex">
                <div className="left">
                  <div className="reviews_heading">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                      <path d="M10.5 12.75H18M10.5 18H22.5M14.5256 27H24.3C26.8202 27 28.0804 27 29.043 26.5095C29.8897 26.0781 30.5781 25.3897 31.0095 24.543C31.5 23.5804 31.5 22.3202 31.5 19.8V11.7C31.5 9.17976 31.5 7.91965 31.0095 6.95704C30.5781 6.11031 29.8897 5.4219 29.043 4.99047C28.0804 4.5 26.8202 4.5 24.3 4.5H11.7C9.17976 4.5 7.91965 4.5 6.95704 4.99047C6.11031 5.4219 5.4219 6.11031 4.99047 6.95704C4.5 7.91965 4.5 9.17976 4.5 11.7V30.5033C4.5 31.3025 4.5 31.7022 4.66384 31.9074C4.80633 32.0859 5.0224 32.1898 5.25081 32.1895C5.51344 32.1892 5.82551 31.9396 6.44963 31.4403L10.0278 28.5777C10.7588 27.993 11.1243 27.7006 11.5312 27.4927C11.8923 27.3082 12.2766 27.1734 12.6738 27.0919C13.1215 27 13.5895 27 14.5256 27Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>My submitted game</span>
                  </div>
                </div>

                {/* <div className="right flex">
                  <FilterGameMenu tools={tools} getResult={filterSearch} />
                </div> */}
              </div>
            </div>

            {filterIsLoading ?
              <div className="loader"></div>
              :

              data.length > 0 ?
                <Game data={data} favourite={favourite} />

                :

                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h2 className="notfound">Not found</h2>
                    </div>
                  </div>
                </div>




            }
            {totalPages > 1 &&
              <div className="container mb32">
                <Pagination count={totalPages} page={currentPage} onClick={pagination} />
              </div>
            }

          </>
      }


      <Footer />
    </>
  )
}

export default GamePage
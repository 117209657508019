import Header from '../Components/header/header'
import Footer from '../Components/footer/footer'
import SubmitGameCom from '../Components/submit-game/submit-game'

const SubmitGame = () => {
  return (
    <>
      <Header />
      <SubmitGameCom />
      <Footer />
    </>
  )
}

export default SubmitGame
import { useState, useEffect } from "react"
import Header from "../../../Components/header/header"
import Footer from "../../../Components/footer/footer"
import ProfileMenu from "../../../Components/profile/profile-menu"
import UserPopup from './news_cat_popup'
import { Icon } from '@iconify/react';

import {
  getAll
} from "../../../api";
import { toast } from "react-toastify";


const ViewUser = ({ type }) => {

  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  useEffect(() => {
    getData();
  }, []);


  const [isLoading, setIsLoading] = useState(true);


  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const { data } = await getAll('/news/category/all');
      if (data.success) {
        setData(data.data.category);
        setId(data.data._id);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const [popup, setPopup] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [popItem, setPopitem] = useState({})
  const popupHandler = (type, item) => {
    setPopup(true)
    setPopupType(type)
    setPopitem(item)
  }

  const closePopup = (refresh) => {
    setPopup(false)
    if (refresh) {
      getData();
    }
  }







  return (
    <>
      <UserPopup isPopup={popup} close={closePopup} popupType={popupType} item={popItem} type={type} id={id} />

      <Header />
      <div className="container admin_container">
        <ProfileMenu className={"flex"}>

          {isLoading ? (

            <div className="loader"></div>
          )
            :
            <>
              <div className="game_heading_wrapper">
                <div className="game_heading_left">
                  <div className="game_heading">
                    <h1>
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path d="M9.00069 16.5H15.0007M12.0007 13.5V19.5M22.5007 18H22.5157M27.0007 15H27.0157M15.6741 7.5H20.3273C24.2646 7.5 26.2333 7.5 27.7785 8.24615C29.139 8.9031 30.2694 9.95563 31.0216 11.2659C31.876 12.754 32.0162 14.7177 32.2968 18.645L32.6658 23.8118C32.8468 26.3451 30.8403 28.5 28.3005 28.5C27.0017 28.5 25.7699 27.9231 24.9384 26.9253L24.3757 26.25C23.861 25.6324 23.6036 25.3235 23.3105 25.0739C22.6962 24.5508 21.9524 24.2024 21.1572 24.0654C20.7779 24 20.3759 24 19.5718 24H16.4295C15.6255 24 15.2235 24 14.8441 24.0654C14.0489 24.2024 13.3052 24.5508 12.6909 25.0739C12.3978 25.3235 12.1404 25.6323 11.6257 26.25L11.0629 26.9253C10.2315 27.9231 8.99972 28.5 7.70088 28.5C5.16105 28.5 3.15461 26.3451 3.33557 23.8118L3.70462 18.645C3.98514 14.7177 4.1254 12.754 4.97975 11.2659C5.73196 9.95563 6.86236 8.9031 8.22288 8.24615C9.76813 7.5 11.7368 7.5 15.6741 7.5Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      News Category List</h1>
                  </div>


                </div>
                <div className="game_heading_right">


                </div>
              </div>


              <div className="admin_table table-responsive">

                <table className="table  ">
                  <thead>
                    <tr>
                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M2.5 16.6667C4.44649 14.6021 7.08918 13.3333 10 13.3333C12.9108 13.3333 15.5535 14.6021 17.5 16.6667M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Category</span>

                      </th>

                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_2411_32076)">
                            <path d="M5.83366 7.91566L1.66699 9.999L9.70218 14.0166C9.8115 14.0713 9.86616 14.0986 9.92349 14.1093C9.97427 14.1189 10.0264 14.1189 10.0772 14.1093C10.1345 14.0986 10.1891 14.0713 10.2985 14.0166L18.3337 9.999L14.167 7.91566M5.83366 12.0823L1.66699 14.1657L9.70218 18.1833C9.8115 18.2379 9.86616 18.2652 9.92349 18.276C9.97427 18.2855 10.0264 18.2855 10.0772 18.276C10.1345 18.2652 10.1891 18.2379 10.2985 18.1833L18.3337 14.1657L14.167 12.0823M1.66699 5.83233L9.70218 1.81473C9.8115 1.76007 9.86616 1.73275 9.92349 1.72199C9.97427 1.71246 10.0264 1.71246 10.0772 1.72199C10.1345 1.73275 10.1891 1.76007 10.2985 1.81473L18.3337 5.83233L10.2985 9.84992C10.1891 9.90458 10.1345 9.93191 10.0772 9.94267C10.0264 9.9522 9.97427 9.9522 9.92349 9.94267C9.86616 9.93191 9.8115 9.90458 9.70218 9.84992L1.66699 5.83233Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2411_32076">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Action</span>

                      </th>
                    </tr>
                  </thead>

                  <tbody>

                    <tr>

                      <td className="category_item">
                        {
                          data.map(item => {
                            if (item) {
                              return (
                                <span>{item}</span>
                              )
                            }
                          }
                          )
                        }
                      </td>

                      <td className="action_icon">
                        <div class="custom_tooltip">
                          <span class="tooltiptext">Edit</span>
                          <Icon onClick={() => popupHandler('edit', data)} icon="mingcute:edit-line" width="24" height="24" />
                        </div>
                      </td>

                    </tr>

                  </tbody>
                </table>


              </div>
            </>

          }
        </ProfileMenu>

      </div>
      <Footer />


    </>

  )
}
export default ViewUser
import React from "react";
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import Game from "../Components/game/game";
import News from "../Components/news/news";
import Slider from '../Components/slider/slider'
import Search from "../Components/search/search";
import { getAll } from "../api";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
export default function HomePage() {
  const ITEMS_PER_PAGE = 10;
  const [gameData, setGameData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [slideData, setSlideData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [keyword, setKeyword] = useState("")

  useEffect(() => {
    getData();
  }, []);


  const [trendingGame, setTrendingGame] = useState([])
  const [topRatedGame, setTopRatedGame] = useState([])
  const [recommendedGame, setRecommendedGame] = useState([])
  const getData = async (category) => {
    setIsLoading(true); // Set loading state
    try {
      const { data } = await getAll('/home/all', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        category: category
      });

      if (data.success) {
        setTrendingGame(data.trendingGame)
        setTopRatedGame(data.topRatedGame)
        setRecommendedGame(data.recommendedGame)
        setNewsData(data.newsData)
        setSlideData(data.slideData)
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  // const paginationData = async (currentPage) => {
  //   setCurrentPage(currentPage)
  //   setIsLoading(true);
  //   try {
  //     const res = await getAll('/admin/search', {
  //       page: currentPage,
  //       limit: ITEMS_PER_PAGE,
  //       type: "news",
  //       keyword: keyword
  //     });
  //     if (res.status === 200) {
  //       setData(res.data.data);
  //       setTotalPages(res.data.totalPages);
  //     } else {
  //       toast.error("Error While Fetching User Data");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   setIsLoading(false);
  // }


  const [catLoader, setCatloader] = useState(true)
  const [category, setCategory] = useState([]);
  useEffect(() => {
    getAll('/news/category/all')
      .then(({ data }) => {
        if (data.success) {
          setCategory(data.data.category)
          setCatloader(false)
        }
      })
  }, []);


  const [currentCat, setCurrentCat] = useState('')


  const getNews = async (category) => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/news/all', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        category: category
      });

      if (res.status === 200) {
        setNewsData(res.data.data);
        // setTotalPages(res.data.totalPages);
      }

    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const categoryChange = (e) => {
    const val = e.target.value
    setCurrentCat(val)
    setCurrentPage(1)
    getNews(val)
  }



  const [currentUrl, setCurrentUrl] = useState('trending')
  const [current, setCurrent] = useState('trending')
  const [curload, setCurload] = useState(false)
  const currentHandler = async (cur, url) => {
    if (cur !== current) {
      setCurload(true)
      setCurrentUrl(url)
      setCurrent(cur)
      const res = await getAll('/games/all', {
        page: 1,
        limit: 10,
        type: cur
      });

      if (res.status === 200) {
        setCurload(false)
        setGameData(res.data.data)
      }
    }
  }


  return (
    <>

      <Header />
      {isLoading ?
        <div className="loader"></div>

        :
        <>

          {slideData.length > 0 &&

            <Slider data={slideData} />
          }
          <Search />

          {curload ? <div className="loader"></div>

            :
            <>
              {/* <div className="col col_right">
                    <div className="game_menu">
                      <button onClick={() => currentHandler("trending", "trending")} className={current == 'trending' && "active"}>Trending</button>
                      <button onClick={() => currentHandler("rating", "top-rated")} className={current == 'rating' && "active"}>Top Rated</button>
                      <button onClick={() => currentHandler("recommend", "recommended")} className={current == 'recommend' && "active"}>Recommend</button>
                    </div>
                  </div> 
              */}

              <div className="container">
                <div className="game_row row game_menu_row">
                  <div className="col col_left">
                    <h3>Top games </h3>
                  </div>
                </div>
              </div>

              <Game type={"slide"} data={trendingGame} currentUrl={"top-games"} />

              <div className="container">
                <div className="game_row row game_menu_row">
                  <div className="col col_left">
                    <h3>Top Rated games </h3>
                  </div>
                </div>
              </div>

              <Game type={"slide"} data={topRatedGame} currentUrl={"top-rated"} />

              <div className="container">
                <div className="game_row row game_menu_row">
                  <div className="col col_left">
                    <h3>Recommend games </h3>
                  </div>
                </div>
              </div>

              <Game type={"slide"} data={recommendedGame} currentUrl={"recommended"} />

            </>
          }
          <News type={"slide"} data={newsData} category={category} categoryChange={categoryChange} currentCat={currentCat} />
        </>
      }

      <Footer />


    </>
  );
}

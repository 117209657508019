import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import { Link, useParams } from "react-router-dom";
import { getOne } from "../api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getToday, imgCheck } from "../helpers/functions";
import ReactHtmlParser from 'react-html-parser'
function SingleNews() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getOne('/news/' + id);
      if (res.status === 200) {
        setData(res.data);
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  return (
    <>
      <Header />
      {
        (!isLoading && data._id) ?
          <div className="container">
            <div className="single_news_row mt16 row  mb32">
              <div className="col">
                <div className="news_heading mb16">
                  <h1>{data.title}</h1>
                </div>

                {data.category &&
                  <div className="news_category">
                    <Link>{data.category}</Link>
                  </div>
                }

                {(data.cover.length > 0) &&
                  <div className="news_img">
                    <img src={imgCheck(data.cover, 0, 'news-banner.png')} alt="" />
                  </div>
                }
                {(data.cover.length === 0 && data.image.length > 0) &&
                  <div className="news_img">
                    <img src={imgCheck(data.image, 0, 'news.png')} alt="" />
                  </div>
                }
                <div className="game_description mt16 mb16">
                  {ReactHtmlParser(data.description)}
                </div>

                <div className="news_extra mt16">
                  <div className="news_author flex">
                    <div className="img mauto">
                      <img src={imgCheck(data.uploadedBy.image, 0, 'user.png')} alt="" />
                    </div>
                    <div className="name mauto">
                      <span className="d-block">{data.uploadedBy.username}</span>
                      <span className="d-block">{getToday(data.createdAt)}</span>
                    </div>
                  </div>


                  {data.tags &&
                    <div className="news_tags flex mt16">
                      {
                        data.tags.split(',').map(item => {
                          return (
                            <div className="tag_item">
                              <Link to={"/"}>{item}</Link>
                            </div>
                          )
                        })
                      }
                    </div>
                  }

                </div>
                {/* <div className="news_reaction mt32 flex">
                  <div className="love flex">
                    <svg className="mauto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.89982 14.6651 9.84977 19.1041 11.4721 20.5408C11.6536 20.7016 11.7444 20.7819 11.8502 20.8135C11.9426 20.8411 12.0437 20.8411 12.1361 20.8135C12.2419 20.7819 12.3327 20.7016 12.5142 20.5408C14.1365 19.1041 19.0865 14.6651 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span className="mauto">Like</span>
                  </div>
                  <div className="comment flex">
                    <svg className="mauto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M7 8.5H12M7 12H15M9.68375 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V20.3355C3 20.8684 3 21.1348 3.10923 21.2716C3.20422 21.3906 3.34827 21.4599 3.50054 21.4597C3.67563 21.4595 3.88367 21.2931 4.29976 20.9602L6.68521 19.0518C7.17252 18.662 7.41617 18.4671 7.68749 18.3285C7.9282 18.2055 8.18443 18.1156 8.44921 18.0613C8.74767 18 9.0597 18 9.68375 18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span className="mauto">Comment</span>
                  </div>
                </div>

                <div className="comment_box mt16">
                  <div className="comment_enter">
                    <textarea name="" id=""></textarea>
                  </div>
                  <div className="comment_button">
                    <button>Comment</button>
                  </div>
                </div> */}


              </div>
            </div>
          </div>

          :
          <div className="loader"></div>
      }

      <Footer />
    </>
  );
}

export default SingleNews;
import { useRef, useState, useContext } from "react"

import Login from "../login/login";
import UserContext from "../../Context/UserContext";
const ReviewPopup = ({ close, gameData }) => {

  const [user, setUser] = useContext(UserContext)
  const [isPopup, setIspopup] = useState(false)
  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setUser({ ...user, loginPopup: false })
    }

  }


  return (
    <div className="popup_wrapper" onClick={clickOutside}>
      <div className="game_review_slider global_scroll" ref={ref}>
        <Login />
      </div>
    </div>
  )
}

export default ReviewPopup
import { useState, useRef, useEffect } from "react"
import { useDropzone } from "react-dropzone";
import { createOne, fileUpload, updateOne } from "../../api";
import { Icon } from '@iconify/react';
import { toast } from "react-toastify";

const defaultValue = {
  'gameplay': 1,
  'story': 1,
  'visuals': 1,
  'sound': 1,
  'controls': 1,
  'replayability': 1,
  'originality': 1,
  'value': 1,
  'technicalPerformance': 1,
  'accessibility': 1,
  'difficultyLevel': 1,
  'hourlyPlayed': 1,
  "review": ""
}
const ReviewPopup = ({ close, isPopup, gameData = {}, mode }) => {

  useEffect(() => {
    if (gameData) {
      setValue({
        gameplay: gameData.gameplay,
        story: gameData.story,
        visuals: gameData.visuals,
        sound: gameData.sound,
        controls: gameData.controls,
        replayability: gameData.replayability,
        originality: gameData.originality,
        value: gameData.value,
        technicalPerformance: gameData.technicalPerformance,
        accessibility: gameData.accessibility,
        difficultyLevel: gameData.difficultyLevel,
        hourlyPlayed: gameData.hourlyPlayed,
        review: gameData.review,
      })
      setGameImage(gameData.attachment)
    }

  }, [gameData])

  const items = [
    'Game Play',
    'Story',
    'Visuals',
    'Sound',
    'Controls',
    'Replayability',
    'Originality',
    'Value',
    'Technical Performance',
    'Accessibility',
    'Difficulty Level',
    'Hourly Game Played'
  ]

  const names = [
    'gameplay',
    'story',
    'visuals',
    'sound',
    'controls',
    'replayability',
    'originality',
    'value',
    'technicalPerformance',
    'accessibility',
    'difficultyLevel',
    'hourlyPlayed'
  ]


  const [value, setValue] = useState(defaultValue)
  const valueHandler = (e) => {
    return false
  }


  const [gameImage, setGameImage] = useState("");

  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }



  return (
    <div className="popup_wrapper" style={{
      display: `${isPopup ? 'block' : 'none'}`
    }} onClick={clickOutside}>
      <div className="game_review_slider global_scroll" ref={ref}>

        <div className="review_popup_text text-center mb16">
          <Icon icon="material-symbols:tab-close" width="36" height="36" onClick={close} />

          <h2 className="">{gameData.type === "Employee" ? "Critic" : "User"} Ratings</h2>

        </div>

        <form>
          <div className="review_input_wrapper">
            {
              items.map((item, index) => (
                <div className="game_input">
                  <label htmlFor="">{item}</label>
                  <span className="total_point">{value[names[index]]}</span>
                  <input onChange={valueHandler} type="range" name={names[index]} id="" min={1} max={10} value={value[names[index]]} />
                </div>
              )
              )
            }





          </div>



        </form>
      </div>
    </div>
  )
}

export default ReviewPopup

import { useState, useEffect, useContext, useRef } from "react"
import ProfileMenu from "./profile-menu"
import UserContext from "../../Context/UserContext"
import { getOne, fileUpload, getAll, updateOne, deleteOne } from "../../api"
import { toast } from "react-toastify"
import { Icon } from "@iconify/react"
import { imgCheck } from "../../helpers/functions"

const Profile = () => {
  const [user, setUser] = useContext(UserContext)
  const [load, setLoad] = useState(true)
  const [isProcessing, setisProcessing] = useState(false)
  const [value, setValue] = useState({})
  const [oldEmail, setOldEmail] = useState("")
  const [oldUsername, setOldUsername] = useState("")
  useEffect(() => {
    setImage(user.image)
    setValue(user)
    setOldEmail(user.email)
    setOldUsername(user.username)

  }, [])

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const [image, setImage] = useState([])
  const profileRef = useRef(null)
  const profileRefClick = (e) => {
    profileRef.current.click()
  }

  const imageHandle = async (e) => {
    const file = e.target.files[0]
    const { data } = await fileUpload('/media/uploads', file, 'round')
    if (data.data && data.data.length > 0 && data.success) {
      setImage(data.data)
    }
  }

  const imgRemoveHandler = async (e) => {
    const { data } = await getAll('/media/delete/', { image: image })
    if (data.success) {
      setImage("")
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const { data } = await updateOne('/users/profile', { ...value, image: image, oldEmail: oldEmail, oldUsername: oldUsername })

    if (data.isUsername) {
      toast.error("This username is used by someone else");
      setisProcessing(false)
    }
    if (data.isEmail) {
      toast.error("This email is used by someone else");
      setisProcessing(false)
    }

    if (data.success && !data.isUsername && !data.isEmail) {
      window.location.reload()
      toast.success("Profile updated successfully");
    }
  }

  return (
    <div className="container">

      <div className="profile">
        {/* <div className="profile_left">
          <ProfileMenu />
        </div> */}
        <div className="profile_right">
          <form onSubmit={onSubmit}>
            <div className="heading">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M13.5 23.25H11.25C9.15666 23.25 8.10998 23.25 7.25829 23.5084C5.34068 24.0901 3.84006 25.5907 3.25836 27.5083C3 28.36 3 29.4067 3 31.5M21.75 11.25C21.75 14.9779 18.7279 18 15 18C11.2721 18 8.25 14.9779 8.25 11.25C8.25 7.52208 11.2721 4.5 15 4.5C18.7279 4.5 21.75 7.52208 21.75 11.25ZM16.5 31.5L21.152 30.1708C21.3748 30.1072 21.4862 30.0754 21.5901 30.0277C21.6823 29.9853 21.77 29.9337 21.8519 29.8736C21.944 29.806 22.0259 29.7241 22.1897 29.5603L31.8751 19.8751C32.9106 18.8395 32.9106 17.1605 31.875 16.125C30.8395 15.0894 29.1606 15.0895 28.125 16.125L18.4398 25.8102C18.2759 25.9741 18.194 26.056 18.1264 26.1481C18.0663 26.23 18.0147 26.3177 17.9723 26.4099C17.9246 26.5138 17.8928 26.6252 17.8291 26.848L16.5 31.5Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>Profile Information</span>
            </div>

            <div className="profile_image flex">
              <div className="left">
                <img src={imgCheck(image, 1, 'user.png')} alt="" />
              </div>
              <div className="middle flex" onClick={profileRefClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M16.45 21H7.18137C6.57555 21 6.27265 21 6.13238 20.8802C6.01068 20.7763 5.94609 20.6203 5.95865 20.4608C5.97312 20.2769 6.18731 20.0627 6.61569 19.6343L15.1186 11.1314C15.5146 10.7354 15.7127 10.5373 15.941 10.4632C16.1418 10.3979 16.3582 10.3979 16.559 10.4632C16.7873 10.5373 16.9854 10.7354 17.3814 11.1314L21.25 15V16.2M16.45 21C18.1302 21 18.9702 21 19.612 20.673C20.1765 20.3854 20.6354 19.9265 20.923 19.362C21.25 18.7202 21.25 17.8802 21.25 16.2M16.45 21H8.05C6.36984 21 5.52976 21 4.88803 20.673C4.32354 20.3854 3.8646 19.9265 3.57698 19.362C3.25 18.7202 3.25 17.8802 3.25 16.2V7.8C3.25 6.11984 3.25 5.27976 3.57698 4.63803C3.8646 4.07354 4.32354 3.6146 4.88803 3.32698C5.52976 3 6.36984 3 8.05 3H16.45C18.1302 3 18.9702 3 19.612 3.32698C20.1765 3.6146 20.6354 4.07354 20.923 4.63803C21.25 5.27976 21.25 6.11984 21.25 7.8V16.2M10.75 8.5C10.75 9.60457 9.85457 10.5 8.75 10.5C7.64543 10.5 6.75 9.60457 6.75 8.5C6.75 7.39543 7.64543 6.5 8.75 6.5C9.85457 6.5 10.75 7.39543 10.75 8.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Change</span>
                <input type="file" name="" id="" ref={profileRef} onChange={imageHandle} />
              </div>

              {image &&
                <div className="right flex" onClick={imgRemoveHandler}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M16.75 6V5.2C16.75 4.0799 16.75 3.51984 16.532 3.09202C16.3403 2.71569 16.0343 2.40973 15.658 2.21799C15.2302 2 14.6701 2 13.55 2H11.95C10.8299 2 10.2698 2 9.84202 2.21799C9.46569 2.40973 9.15973 2.71569 8.96799 3.09202C8.75 3.51984 8.75 4.0799 8.75 5.2V6M10.75 11.5V16.5M14.75 11.5V16.5M3.75 6H21.75M19.75 6V17.2C19.75 18.8802 19.75 19.7202 19.423 20.362C19.1354 20.9265 18.6765 21.3854 18.112 21.673C17.4702 22 16.6302 22 14.95 22H10.55C8.86984 22 8.02976 22 7.38803 21.673C6.82354 21.3854 6.3646 20.9265 6.07698 20.362C5.75 19.7202 5.75 18.8802 5.75 17.2V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span>Remove</span>
                </div>
              }
            </div>

            <div className="profile_input_wrapper flex">

              <div className="profile_input_box">
                <label htmlFor="">User Name</label>
                <input type="text" placeholder="Enter your username" name="username" onChange={onChange} value={value.username} />
              </div>
              <div className="profile_input_box">
                <label htmlFor="">Email Address</label>
                <input type="email" placeholder="Enter your email address" name="email" onChange={onChange} value={value.email} />
              </div>
              <div className="profile_input_box">
                <label htmlFor="">First Name <span className="optional"> (Optional)</span></label>
                <input type="text" placeholder="Enter your first name" name="fname" onChange={onChange} value={value.fname} />
              </div>
              <div className="profile_input_box">
                <label htmlFor="">Last Name <span className="optional"> (Optional)</span></label>
                <input type="text" placeholder="Enter your last name" name="lname" onChange={onChange} value={value.lname} />
              </div>
              <div className="profile_input_box">
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Enter your address" name="address" onChange={onChange} value={value.address} />
              </div>
              <div className="profile_input_box">
                <label htmlFor="">Description</label>
                <textarea type="text" placeholder="Write something about you" name="description" onChange={onChange} value={value.description} />
              </div>
            </div>
            <div className="profile_btn">
              {/* <button type="button">Cancel</button> */}
              <button type="submit gradient_button">Update</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Profile
import { useState, useEffect } from "react"
import Header from "../../../Components/header/header"
import Footer from "../../../Components/footer/footer"
import ProfileMenu from "../../../Components/profile/profile-menu"
import UserPopup from './user-popup'
import { Icon } from '@iconify/react';
import Pagination from "../../../Components/pagination/pagination"
import {
  getAll
} from "../../../api";
import { toast } from "react-toastify";
import { imgCheck } from "../../../helpers/functions"


const ViewUser = ({ type }) => {

  const ITEMS_PER_PAGE = 10;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!keyword) {
      getData();
    } else {
      paginationData(currentPage)
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, type]);

  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/users/all', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: type,
      });
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const paginationData = async (currentPage) => {
    const trimKeyword = keyword.trim()
    if (trimKeyword) {

      setCurrentPage(currentPage)
      setIsLoading(true);
      try {
        const res = await getAll('/admin/search', {
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          type: "user",
          userType: type,
          keyword: trimKeyword
        });
        if (res.status === 200) {
          setData(res.data.data);
          setTotalPages(res.data.totalPages);
        } else {
          toast.error("Error While Fetching User Data");
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);

    }
  }

  const [popup, setPopup] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [popItem, setPopitem] = useState({})
  const popupHandler = (type, item) => {
    setPopup(true)
    setPopupType(type)
    setPopitem(item)
  }

  const closePopup = (refresh) => {
    setPopup(false)
    if (refresh) {
      getData();
    }
  }

  const pagination = (item) => {
    setCurrentPage(item)
  }





  const [keyword, setKeyword] = useState("")

  const searchhandler = async () => {
    paginationData(1)
  }



  return (
    <>
      <UserPopup isPopup={popup} close={closePopup} popupType={popupType} item={popItem} type={type} />

      <Header />
      <div className="container admin_container">
        <ProfileMenu className={"flex"}>

          {isLoading ? (

            <div className="loader"></div>
          )
            :
            <>
              <div className="game_heading_wrapper">
                <div className="game_heading_left">
                  <div className="game_heading">
                    <h1><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                      <path d="M9.00069 16.5H15.0007M12.0007 13.5V19.5M22.5007 18H22.5157M27.0007 15H27.0157M15.6741 7.5H20.3273C24.2646 7.5 26.2333 7.5 27.7785 8.24615C29.139 8.9031 30.2694 9.95563 31.0216 11.2659C31.876 12.754 32.0162 14.7177 32.2968 18.645L32.6658 23.8118C32.8468 26.3451 30.8403 28.5 28.3005 28.5C27.0017 28.5 25.7699 27.9231 24.9384 26.9253L24.3757 26.25C23.861 25.6324 23.6036 25.3235 23.3105 25.0739C22.6962 24.5508 21.9524 24.2024 21.1572 24.0654C20.7779 24 20.3759 24 19.5718 24H16.4295C15.6255 24 15.2235 24 14.8441 24.0654C14.0489 24.2024 13.3052 24.5508 12.6909 25.0739C12.3978 25.3235 12.1404 25.6323 11.6257 26.25L11.0629 26.9253C10.2315 27.9231 8.99972 28.5 7.70088 28.5C5.16105 28.5 3.15461 26.3451 3.33557 23.8118L3.70462 18.645C3.98514 14.7177 4.1254 12.754 4.97975 11.2659C5.73196 9.95563 6.86236 8.9031 8.22288 8.24615C9.76813 7.5 11.7368 7.5 15.6741 7.5Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                      {type} List</h1>
                  </div>


                </div>
                <div className="game_heading_right">
                  <div className="input_item">
                    <Icon icon="prime:search" width="24" height="24" />
                    <input placeholder="Search" type="text" name="" id="" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                  </div>
                  <div className="filter_icon">
                    <button onClick={searchhandler}>Search</button>
                  </div>
                  <div className="add_game">
                    <button onClick={() => popupHandler('add')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25Z" fill="white" />
                      </svg> Add User</button>
                  </div>
                </div>
              </div>


              <div className="admin_table table-responsive">

                <table className="table  ">
                  <thead>
                    <tr>
                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M2.5 16.6667C4.44649 14.6021 7.08918 13.3333 10 13.3333C12.9108 13.3333 15.5535 14.6021 17.5 16.6667M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Name</span>

                      </th>

                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M17.0833 6.06527L9.99997 10.0005M9.99997 10.0005L2.91664 6.06527M9.99997 10.0005L10 17.9171M17.5 13.3826V6.61835C17.5 6.33281 17.5 6.19004 17.4579 6.06271C17.4207 5.95007 17.3599 5.84666 17.2795 5.75942C17.1886 5.66081 17.0638 5.59147 16.8142 5.4528L10.6475 2.02688C10.4112 1.89558 10.293 1.82993 10.1679 1.80419C10.0571 1.78141 9.94288 1.78141 9.83213 1.80419C9.70698 1.82993 9.58881 1.89558 9.35248 2.02688L3.18581 5.45281C2.93621 5.59148 2.8114 5.66081 2.72053 5.75942C2.64013 5.84667 2.57929 5.95007 2.54207 6.06271C2.5 6.19005 2.5 6.33281 2.5 6.61835V13.3826C2.5 13.6681 2.5 13.8109 2.54207 13.9382C2.57929 14.0509 2.64013 14.1543 2.72053 14.2415C2.8114 14.3401 2.93621 14.4095 3.18581 14.5481L9.35248 17.9741C9.58881 18.1054 9.70698 18.171 9.83213 18.1968C9.94288 18.2195 10.0571 18.2195 10.1679 18.1968C10.293 18.171 10.4112 18.1054 10.6475 17.9741L16.8142 14.5481C17.0638 14.4095 17.1886 14.3401 17.2795 14.2415C17.3599 14.1543 17.4207 14.0509 17.4579 13.9382C17.5 13.8109 17.5 13.6681 17.5 13.3826Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Last Visited</span>

                      </th>
                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.00033 10H8.33366M6.66699 8.33333V11.6667M12.5003 10.8333H12.5087M15.0003 9.16667H15.0087M4.33366 15H15.667C16.6004 15 17.0671 15 17.4236 14.8183C17.7372 14.6586 17.9922 14.4036 18.152 14.09C18.3337 13.7335 18.3337 13.2668 18.3337 12.3333V7.66667C18.3337 6.73325 18.3337 6.26654 18.152 5.91002C17.9922 5.59641 17.7372 5.34144 17.4236 5.18166C17.0671 5 16.6004 5 15.667 5H4.33366C3.40024 5 2.93353 5 2.57701 5.18166C2.2634 5.34144 2.00844 5.59641 1.84865 5.91002C1.66699 6.26654 1.66699 6.73325 1.66699 7.66667V12.3333C1.66699 13.2668 1.66699 13.7335 1.84865 14.09C2.00844 14.4036 2.2634 14.6586 2.57701 14.8183C2.93353 15 3.40024 15 4.33366 15Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Games Posted</span>

                      </th>
                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_2411_32076)">
                            <path d="M5.83366 7.91566L1.66699 9.999L9.70218 14.0166C9.8115 14.0713 9.86616 14.0986 9.92349 14.1093C9.97427 14.1189 10.0264 14.1189 10.0772 14.1093C10.1345 14.0986 10.1891 14.0713 10.2985 14.0166L18.3337 9.999L14.167 7.91566M5.83366 12.0823L1.66699 14.1657L9.70218 18.1833C9.8115 18.2379 9.86616 18.2652 9.92349 18.276C9.97427 18.2855 10.0264 18.2855 10.0772 18.276C10.1345 18.2652 10.1891 18.2379 10.2985 18.1833L18.3337 14.1657L14.167 12.0823M1.66699 5.83233L9.70218 1.81473C9.8115 1.76007 9.86616 1.73275 9.92349 1.72199C9.97427 1.71246 10.0264 1.71246 10.0772 1.72199C10.1345 1.73275 10.1891 1.76007 10.2985 1.81473L18.3337 5.83233L10.2985 9.84992C10.1891 9.90458 10.1345 9.93191 10.0772 9.94267C10.0264 9.9522 9.97427 9.9522 9.92349 9.94267C9.86616 9.93191 9.8115 9.90458 9.70218 9.84992L1.66699 5.83233Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2411_32076">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Joining Date</span>

                      </th>
                      <th>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clip-path="url(#clip0_2411_32076)">
                            <path d="M5.83366 7.91566L1.66699 9.999L9.70218 14.0166C9.8115 14.0713 9.86616 14.0986 9.92349 14.1093C9.97427 14.1189 10.0264 14.1189 10.0772 14.1093C10.1345 14.0986 10.1891 14.0713 10.2985 14.0166L18.3337 9.999L14.167 7.91566M5.83366 12.0823L1.66699 14.1657L9.70218 18.1833C9.8115 18.2379 9.86616 18.2652 9.92349 18.276C9.97427 18.2855 10.0264 18.2855 10.0772 18.276C10.1345 18.2652 10.1891 18.2379 10.2985 18.1833L18.3337 14.1657L14.167 12.0823M1.66699 5.83233L9.70218 1.81473C9.8115 1.76007 9.86616 1.73275 9.92349 1.72199C9.97427 1.71246 10.0264 1.71246 10.0772 1.72199C10.1345 1.73275 10.1891 1.76007 10.2985 1.81473L18.3337 5.83233L10.2985 9.84992C10.1891 9.90458 10.1345 9.93191 10.0772 9.94267C10.0264 9.9522 9.97427 9.9522 9.92349 9.94267C9.86616 9.93191 9.8115 9.90458 9.70218 9.84992L1.66699 5.83233Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2411_32076">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Action</span>

                      </th>
                    </tr>
                  </thead>

                  <tbody>

                    {data.map(user => (
                      <tr>
                        <td>
                          <div className="profile_icon">
                            <div className="img">
                              <img src={imgCheck(user.image, 0, 'user.png')} alt="" />
                            </div>
                            <div className="name">
                              {user.username}
                            </div>

                          </div>
                        </td>
                        <td>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "No Visit"}</td>
                        <td>{user.gamesPosted || 0}</td>
                        <td>{new Date(user.joinedOn).toLocaleString()}</td>
                        <td className="action_icon">


                          <div class="custom_tooltip">
                            <span class="tooltiptext">Edit</span>
                            <Icon onClick={() => popupHandler('edit', user)} icon="mingcute:edit-line" width="24" height="24" />
                          </div>
                          <div class="custom_tooltip">
                            <span class="tooltiptext">View</span>
                            <Icon onClick={() => popupHandler('view', user)} icon="mdi:eye" width="24" height="24" />
                          </div>

                          <div class="custom_tooltip">
                            <span class="tooltiptext">Delete</span>
                            <Icon onClick={() => popupHandler('delete', user)} icon="ant-design:delete-outlined" width="24" height="24" />
                          </div>

                          {user.status === "Approved" ?
                            <div class="custom_tooltip">
                              <span class="tooltiptext">Block</span>
                              <Icon onClick={() => popupHandler('block', user)} icon="mdi:tick-circle-outline" width="24" height="24" />
                            </div>
                            :

                            <div class="custom_tooltip">
                              <span class="tooltiptext">Unblock</span>
                              <Icon onClick={() => popupHandler('unblock', user)} icon="material-symbols:block" width="24" height="24" />
                            </div>

                          }



                          {/* <Link to={"/"}><Icon icon="material-symbols:link" width="24" height="24" /></Link> */}
                        </td>

                      </tr>
                    ))}

                  </tbody>
                </table>

                {totalPages > 1 &&
                  <Pagination count={totalPages} page={currentPage} onClick={pagination} />
                }
              </div>
            </>

          }
        </ProfileMenu>

      </div>
      <Footer />


    </>

  )
}
export default ViewUser
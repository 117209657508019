import { useState, useRef, useEffect } from "react"
import { updateOne, fileUpload } from "../../../api";
import { toast } from "react-toastify";
import { Icon } from '@iconify/react';
import Dropzone from "react-dropzone";

const ReviewPopup = ({ close, isPopup, popupType, item, id, type }) => {
  const [isProcessing, setisProcessing] = useState(false);
  const [value, setValue] = useState([{
    title: '',
    image: ''
  }])


  useEffect(() => {
    if (item && item.length > 0) {
      setValue(item)
    } else {
      setValue([{
        title: '',
        image: ''
      }])
    }
  }, [item, type])

  const onChange = (e, index) => {
    const copyValue = [...value]
    copyValue.splice(index, 1, { ...copyValue[index], [e.target.name]: e.target.value })
    setValue(copyValue)
  }


  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }

  const updateUser = async (e) => {
    e.preventDefault()
    if (id) {
      setisProcessing(true);
      try {
        const { data } = await updateOne("/games/tools/" + id, {
          [type]: value
        });
        if (data.success) {
          setValue([])
          toast.success("Genre Information Updated");
          close(true)
        }

      } catch (error) {
        console.error("Error:", error);
        toast.error(
          error.response.data.error || "Error updating Genre"
        );
      } finally {
        setisProcessing(false);
      }
    }
  };


  const addMore = () => {
    const copyValue = [...value]
    copyValue.push({
      title: '',
      image: ''
    })
    setValue(copyValue)
  }

  const removeHandler = (index) => {
    const copyValue = [...value]
    copyValue.splice(index, 1)
    setValue(copyValue)
  }


  const onDrop = async (acceptedFiles, index) => {
    const file = acceptedFiles[0];
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (file && file.size > maxSize) {
      alert("File size exceeds the maximum limit of 10MB");
      return; // Do not proceed further
    }
    const { data } = await fileUpload('/media/uploads', file)
    if (data.url) {
      const copyValue = [...value]
      copyValue.splice(index, 1, { ...copyValue[index], image: data.url })
      setValue(copyValue)
    }

  }

  switch (popupType) {


    case 'edit':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {isProcessing ?
              <div className="loader"></div>
              :
              <div className="edit_box">

                <form onSubmit={updateUser}>
                  <div className="input_items category_input">

                    <div className="input_item">

                      {value.map((item, i) => (
                        <>
                          <div className="dynamic_store_wrapper">
                            <div className="dynamic_store item" key={i}>
                              <label className="dynamic_game_label" htmlFor="">{type}</label>
                              <input placeholder={"Enter " + type + " name"} onChange={(e) => onChange(e, i)} type="text" value={value[i].title} name="title" />


                              <label htmlFor="" className="mt16">
                                Image
                              </label>

                              <div className="video_input dynamic_admin_img">
                                <Dropzone onDrop={(e) => onDrop(e, i)}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="container">
                                      <div
                                        {...getRootProps({
                                          className: 'dropzone',
                                          onDrop: event => event.stopPropagation()
                                        })}
                                      >
                                        <input {...getInputProps()} />

                                        {
                                          value[i].image ? (
                                            // Display image preview
                                            <img
                                              src={value[i].image}
                                              alt="Preview"
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                                maxHeight: 200,
                                              }}
                                            />
                                          )
                                            : (<>
                                              <div className="upload_input">
                                                <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                                <span>Uploads </span>
                                              </div>

                                            </>)}

                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>




                              <div className="category_remove_button">
                                <p onClick={() => removeHandler(i)}>Remove</p>
                              </div>



                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                  </div>

                  <div className="remove_box">
                    <div className="button_item">
                      <button onClick={addMore} type="button" className="add gradient_button">Add More</button>
                      <button type="submit" className="delete gradient_button">Update</button>
                      <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            }

          </div>
        </div>
      )

    case 'view':
      return (

        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="view_data admin_table">
              <table className="">
                <tbody>
                  <tr>
                    <td>Category:</td>
                    <td>{item.category}</td>
                  </tr>
                  <tr>
                    <td>order:</td>
                    <td>{item.order}</td>
                  </tr>

                </tbody>
              </table>


              <div className="remove_box mt16">
                <div className="button_item">
                  <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      )


  }

}

export default ReviewPopup
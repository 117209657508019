import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import ThirdPartyLogin from "../Components/auth/third-party-login";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getAll } from "../api";
import { jwtDecode } from "jwt-decode";
import UserContext from "../Context/UserContext";
export default function Login({ }) {
  const [user, setUser] = useContext(UserContext)
  const [email, setemail] = useState("");
  const [username, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [success, setSuccess] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const [isProcessing, setisProcessing] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("playercritic_user_Token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);
  const handleGenerateUniqueId = () => {
    // Generate a random 8-character string for the unique ID
    const uniqueString = Math.random().toString(36).substring(2, 12);
    setUniqueId(uniqueString);
    return uniqueString;
  };

  const doSignup = async (e) => {
    e.preventDefault()
    if (!username || !email || !password) {
      toast.error("Kindly Fill All Inputs");
      return;
    } else {
      setisProcessing(true);
    }

    try {
      var { data } = await getAll("/users/register", {
        username,
        email,
        uniqueId: handleGenerateUniqueId(),
        password,
        type: "User",
        login: true,
      });

      if (data.isUsername) {
        toast.error("This username is used by someone else");
        setisProcessing(false)
      }
      if (data.isEmail) {
        toast.error("This email is used by someone else");
        setisProcessing(false)
      }


      if (data.success && !data.isUsername && !data.isEmail) {
        toast.success("Signup completed successfully");
        setSuccess(true)
      }
    } catch (error) {
      // Handle the error and show the error message using toast
      console.error("Error occurred:", error);
      // If there are validation errors, display them in the toast
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        validationErrors.forEach((err) => {
          console.log(err)
          toast.error(err.msg);
        });

      } else {
        if (error.response.data.username) {
          toast.error(error.response.data.username)
        }
        if (error.response.data.msg) {
          toast.error(
            error.response.data.msg ||
            "An error occurred. Please try again later."
          );
        }

      }
      setisProcessing(false);
    }
  };

  return (
    <>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Header />
      <section style={{
        minHeight: "100vh",
        position: "relative",
        background: `url(${'./auth-bg.png'})`,
        backgroundSize: "100% auto",
        backgroundRepeat: "no-repeat",

      }}>
        <div className="login_shadow"></div>

        <div className="container">
          <div className="row login_row">
            <div className="col login_col">
              <div className="login_box">



                <h1>Create an Account</h1>
                <p>Enter your details or continue with Google,Facebook or Apple ID</p>

                {
                  success &&
                  <div className="alert alert-danger mb32">
                    Please confirm your email to <Link className="login_anchor" to={"/login"}>login</Link>
                  </div>
                }


                {!success && <>
                  <form onSubmit={doSignup}>
                    <div className="login_input">
                      <label htmlFor="">User Name</label>
                      <Icon icon="ic:baseline-email" width="24" height="24" />
                      <input type="text" name="" id=""
                        value={username}
                        placeholder="Enter your username"
                        onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="login_input">
                      <label htmlFor="">Email</label>
                      <Icon icon="ic:baseline-email" width="24" height="24" />
                      <input type="text" name="" id=""
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)} />
                    </div>
                    <div className="login_input">
                      <label htmlFor="">Password</label>
                      <Icon icon="material-symbols:lock" width="24" height="24" />

                      <input type="password" name="" id=""
                        placeholder="Enter your password"
                        value={password}
                        onChange={handlePasswordChange} />
                    </div>
                    <div className="login_btn">

                      {isProcessing ?


                        <div className="button circle">
                          <div className="loader_circle"></div>
                        </div>
                        :
                        <button className="button" type="submit">Create Account</button>
                      }

                    </div>
                  </form>

                  <div className="flex mt16 mb16">
                    <div className="line mauto flex-grow left_line"></div>
                    <h5 className="text text-white mauto flex-grow ">OR</h5>
                    <div className="line mauto flex-grow right_line"></div>
                  </div>

                  <ThirdPartyLogin />

                  <div className="already_account mt16">
                    <span>Already have an account? </span> <Link to={"/login"}>&nbsp; login</Link>
                  </div>
                </>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

import { Link } from "react-router-dom"
import { useContext, useEffect, useState, useRef } from 'react'
import UserContext, { handleLogout } from "../../Context/UserContext";
import { Icon } from '@iconify/react';
import { screen } from "../../helpers/functions";
import { useNavigate } from "react-router-dom";
export default function ProfileMenu({ children, className = "" }) {
  const navigate = useNavigate()
  const [user, setUser] = useContext(UserContext);

  const [adminMenu, setAdminMenu] = useState(
    [
      {
        name: 'Profile Information',
        link: '/profile'
      },
      // {
      //   name: 'Reviews',
      //   link: '/reviews'
      // },
      {
        name: 'Games',
        link: '/games'
      },
      // {
      //   name: 'Notification',
      //   link: '/notification'
      // },

    ]
  )
  useEffect(() => {
    if (user.type === 'Admin') {
      setAdminMenu([
        {
          name: 'Games',
          link: '/admin/games'
        },
        {
          name: 'Game Tools',
          link: '/admin/games/genre'
        },
        {
          name: 'Game Store',
          link: '/admin/games/store'
        },

        {
          name: 'News',
          link: '/admin/news'
        },
        {
          name: 'News Category',
          link: '/admin/news/category'
        },
        {
          name: 'Admin',
          link: '/admin/user/admin'
        },
        {
          name: 'Employee',
          link: '/admin/user/employee'
        },
        {
          name: 'User',
          link: '/admin/user/user'
        },


        {
          name: 'Reviews',
          link: '/admin/reviews'
        },
        // {
        //   name: 'Profile Information',
        //   link: '/profile'
        // },

        // {
        //   name: 'Notification',
        //   link: '/notification'
        // },

      ])
    }

  }, [])

  const [mobilemenu, setMobilemenu] = useState(screen() > 991 ? true : false)

  const mobileMenuHandler = () => {
    setMobilemenu(!mobilemenu)
  }


  const menuRef = useRef(null);


  useEffect(() => {
    if (screen() < 992) {
      const menuClickOutside = (e) => {
        if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
          setMobilemenu(false)
        }
      };

      window.addEventListener("click", menuClickOutside);
      return () => window.removeEventListener("click", menuClickOutside);
    }
  }, [])



  return (
    <>
      <div className={"profile_menu_box_wrapper " + className}>
        <div className="admin_left">
          <div className="mobile_menu_cion">
            <div className="svg_border" ref={menuRef} onClick={mobileMenuHandler} >
              <Icon icon="ep:menu" width="24" height="24" />
            </div>
          </div>
          {mobilemenu &&
            <div className="profile_menu_box">


              {adminMenu.map(item => (
                <div className="profile_menu_item_border">
                  <div className="profile_menu_item">
                    <Link className="profile_menu_link" to={item.link}>
                      <div className="left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M9 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5ZM11 21L14.1014 20.1139C14.2499 20.0715 14.3241 20.0502 14.3934 20.0184C14.4549 19.9902 14.5134 19.9558 14.5679 19.9158C14.6293 19.8707 14.6839 19.8161 14.7932 19.7068L21.25 13.25C21.9404 12.5597 21.9404 11.4403 21.25 10.75C20.5597 10.0596 19.4404 10.0596 18.75 10.75L12.2932 17.2068C12.1839 17.3161 12.1293 17.3707 12.0842 17.4321C12.0442 17.4866 12.0098 17.5451 11.9816 17.6066C11.9497 17.6759 11.9285 17.7501 11.8861 17.8987L11 21Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="right">
                        <p>{item.name}</p>
                      </div>
                    </Link>
                  </div>

                </div>
              ))}

              <div className="profile_menu_item logout">
                <a className="profile_menu_link" onClick={() => handleLogout(setUser, navigate)}>
                  <div className="left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M16 17L21 12M21 12L16 7M21 12H9M12 17C12 17.93 12 18.395 11.8978 18.7765C11.6204 19.8117 10.8117 20.6204 9.77646 20.8978C9.39496 21 8.92997 21 8 21H7.5C6.10218 21 5.40326 21 4.85195 20.7716C4.11687 20.4672 3.53284 19.8831 3.22836 19.1481C3 18.5967 3 17.8978 3 16.5V7.5C3 6.10217 3 5.40326 3.22836 4.85195C3.53284 4.11687 4.11687 3.53284 4.85195 3.22836C5.40326 3 6.10218 3 7.5 3H8C8.92997 3 9.39496 3 9.77646 3.10222C10.8117 3.37962 11.6204 4.18827 11.8978 5.22354C12 5.60504 12 6.07003 12 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="right">
                    <p>Logout</p>
                  </div>
                </a>
              </div>

            </div>
          }
        </div>
        <div className="admin_right">
          {children}
        </div>
      </div>

    </>
  )
}



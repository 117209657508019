
export const reviewNames = ['Game Play', 'Story', 'Visuals', 'Sounds', 'Controls',
  'Replayability', 'Originality', 'Value', 'Technical Performance', 'Accessibility'
]

export const reviewKeys = [
  'gameplay',
  'story',
  'visuals',
  'sound',
  'controls',
  'replayability',
  'originality',
  'value',
  'technicalPerformance',
  'accessibility',
  'difficultyLevel',
  'hourlyPlayed'
]

export const reviewKeysName = [
  'Game Play', 'Story', 'Visuals', 'Sounds', 'Controls',
  'Replayability', 'Originality', 'Value', 'Technical Performance', 'Accessibility',
  'Difficulty Level', 'Hourly Played'

]
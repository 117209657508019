
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useContext, useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import UserContext from "../../Context/UserContext";
import ThirdPartyLogin from "../../Components/auth/third-party-login";
import { getAll } from "../../api";


function Login() {
  const [user, setUser] = useContext(UserContext);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const [loginError, setloginError] = useState("");
  const [isProcessing, setisProcessing] = useState(false);

  useEffect(() => {

    if (user.userId) {
      navigate("/");
    }
  }, [user, navigate]);


  const doLogin = async (e) => {
    e.preventDefault()
    if (!password || !email) {
      toast.error("Kindly fill all inputs");
      return;
    }

    try {
      setisProcessing(true);
      const response = await getAll("/users/login", { email, password });
      if (response.status == 200) {

        const decoded = jwtDecode(response.data.token);
        localStorage.setItem("playercritic_user_Token", response.data.token);
        setUser({
          userId: decoded.user._id,
          email: decoded.user.email,
          username: decoded.user.username,
          image: decoded.user.image,
          type: decoded.user.type,
          fname: decoded.user.fname,
          lname: decoded.user.fname,
          description: decoded.user.description,
          address: decoded.user.address,
        });
        if (response.status == 200) {
          window.location.href = localStorage.getItem('page')
        }
      } else {
        toast.error("Invalid Email or Password");
        setisProcessing(false);
      }
      // Save the token and userId to the local storage or state management system
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.msg || "Error while logging in");
      setisProcessing(false);
    }
  };

  const clickRef = useRef(null)


  const clickOutside = (e) => {
    if (clickRef && clickRef.current && !clickRef.current.contains(e.target)) {

    }
  }


  return (
    <section style={{
      minHeight: "100vh",
      position: "relative",
      background: `url(${'./auth-bg.png'})`,
      backgroundSize: "100% auto",
      backgroundRepeat: "no-repeat",

    }}>
      <div className="login_shadow"></div>

      <div className="container">
        <div className="row login_row">
          <div className="col login_col">
            <div className="login_box">
              <h1>Welcome Back</h1>
              <p>Enter your details or continue with Google,Facebook or Apple ID</p>
              <form onSubmit={doLogin}>
                <div className="login_input">
                  <label htmlFor="">Email</label>
                  <Icon icon="ic:baseline-email" width="24" height="24" />
                  <input type="text" name="" id=""
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <div className="login_input">
                  <label htmlFor="">Password</label>
                  <Icon icon="material-symbols:lock" width="24" height="24" />
                  <Icon icon="mdi:eye" width="24" height="24" className="eye" onClick={toggleShowPassword} />
                  <input type={showPassword ? "text" : "password"} name="" id=""
                    placeholder="Enter your password"
                    value={password} onChange={handlePasswordChange} className="eye_input" />
                </div>

                <div className="login_btn">

                  {isProcessing ?


                    <div className="button circle">
                      <div className="loader_circle"></div>
                    </div>
                    :
                    <button className="button" type="submit">Login</button>
                  }

                </div>
              </form>
              <div className="forget_link">
                <Link to={"/forget"}>Forget Password</Link>
              </div>

              <div className="flex mt16 mb16">
                <div className="line mauto flex-grow left_line"></div>
                <h5 className="text text-white mauto flex-grow ">OR</h5>
                <div className="line mauto flex-grow right_line"></div>
              </div>

              <ThirdPartyLogin />

              <div className="already_account mt16">
                <Link to={"/signup"}>Create an Account</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Login;

import News from "../Components/news/news";
import Header from "../Components/header/header"
import Footer from "../Components/footer/footer"
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getAll } from "../api";
import Pagination from "../Components/pagination/pagination";
function NewsPage() {
  const ITEMS_PER_PAGE = 10;
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(true);


  const [catLoader, setCatloader] = useState(true)
  const [category, setCategory] = useState([]);
  useEffect(() => {
    getAll('/news/category/all')
      .then(({ data }) => {
        if (data.success) {
          setCategory(data.data.category)
          setCatloader(false)
        }
      })
  }, []);


  const [currentCat, setCurrentCat] = useState('')

  const categoryChange = (e) => {
    const val = e.target.value
    setCurrentCat(val)
    setCurrentPage(1)
    getData(val)
  }

  useEffect(() => {
    if (!keyword) {
      getData();
    } else {
      paginationData(currentPage)
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);



  const getData = async (category) => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/news/all', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        category: category
      });

      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);

      }

    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const paginationData = async (currentPage) => {

    setCurrentPage(currentPage)
    setIsLoading(true);
    try {
      const res = await getAll('/admin/search', {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        type: "news",
        keyword: keyword
      });
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
      } else {
        toast.error("Error While Fetching User Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  const pagination = (item) => {
    setCurrentPage(item)
  }


  return (
    <>
      <Header />
      {
        isLoading ?
          <div className="loader"></div>
          :

          <>

            <News data={data} category={category} categoryChange={categoryChange} currentCat={currentCat} />
            {totalPages > 1 &&
              <div className="container mb32">
                <Pagination count={totalPages} page={currentPage} onClick={pagination} />
              </div>
            }
          </>
      }

      <Footer />
    </>
  );
}

export default NewsPage;



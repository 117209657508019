import { Splide } from "@splidejs/react-splide"
import '@splidejs/react-splide/css';
// import '../../splide-skyblue.min.css';
// import "./all-carousel.css"

const CarouselAll = ({ children, page, title, link, options, className, pagination = false, arrows = true }) => {
	const opt = {
		arrows: arrows,
		rewind: false,
		pagination: pagination,

		perPage: page || 4,
		gap: "1rem",

		// autoWidth: true,

		breakpoints: options || {
			991: {
				perPage: 3,
			},
			900: {
				perPage: 2,
			},
			576: {
				perPage: 1,
			},
		},
	}
	return (
		<div className={"carousel_all " + className}>
			<div className={"carousel_heading row "}>
				<h3 >{title}</h3>
			</div>
			<Splide options={opt} aria-label="">
				{children}
			</Splide>
		</div>
	)
}
export default CarouselAll

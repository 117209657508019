
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientsvg";

export default function Progress({ width = 130, height = 130, value = 9.34, textValue = value }) {
  const idCSS = "hello";


  if (value === 0) textValue = "N/A"; value = 10
  return (
    <div className="circle_progress">
      <div style={{ height: width + "px", width: height + "px" }}>
        <GradientSVG />
        <CircularProgressbar
          strokeWidth={8}
          value={value * 10}
          text={textValue}
          styles={{
            path: { stroke: `url(#${idCSS})`, height: "100%" },
            trail: {
              stroke: "#fff"
            }
          }}
        />
      </div>
    </div>
  );
}

import { useState, useRef, useEffect, useContext } from "react"
import { updateOne, deleteOne, createOne, fileUpload } from "../../../api";
import { toast } from "react-toastify";
import { Icon } from '@iconify/react';
import Editor from '../editor/editor'
import { useDropzone } from "react-dropzone";
import UserContext from "../../../Context/UserContext";
import { imgUrl } from "../../../helpers/functions";


const ReviewPopup = ({ close, isPopup, popupType, item, type, category }) => {

  const [user, setUser] = useContext(UserContext);
  const [image, setImage] = useState([]);
  const [cover, setCover] = useState([]);
  const [isProcessing, setisProcessing] = useState(false);
  const [value, setValue] = useState({

  })
  const [oldValue, setOldValue] = useState({
    status: 'Published',

  })
  const [cat, setCat] = useState([])

  useEffect(() => {
    if (popupType !== 'add') {
      setValue(item)
      setOldValue(item)
      if (item.image) setImage(item.image)
      if (item.cover) setCover(item.cover)
      if (item.description) setDescription(item.description)
    }
    if (popupType === 'add') {
      setValue({
        title: "",
        description: "",
        category: "",
        status: "",
        tags: ""
      })
      setImage([])
      setCover([])
      setDescription("")
    }
  }, [item, popupType])

  useEffect(() => {
    if (category.length > 0) setCat(category)
  }, [category])

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }


  const ref = useRef()

  const clickOutside = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      close(false)
    }

  }


  const { getRootProps: getRootThubProps, getInputProps: getInputThubProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return;
      }
      const { data } = await fileUpload('/media/uploads', file)
      if (data.data) {
        setImage(data.data)
      }
    },
  });

  const { getRootProps: getRootCoverProps, getInputProps: getInputCoverProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return;
      }
      const { data } = await fileUpload('/media/uploads', file, 'banner')
      if (data.data) {
        setCover(data.data)
      }
    },
  });

  const addUser = async (e) => {
    e.preventDefault()
    setisProcessing(true);
    try {
      const { data } = await createOne("/news/create", {
        title: value.title,
        description: description,
        image: image,
        cover: cover,
        category: value.category,
        status: value.status,
        tags: value.tags,
        uploadedBy: user.userId
      });

      if (data.success) {
        toast.success("News aded successfully");
        close(true)
      }

      setValue({
        title: "",
        description: "",
        category: "",
        status: "",
        tags: ""
      })
      setImage([])
      setCover([])
      setDescription('')

    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response.data.error || "Error updating user information"
      );
    } finally {
      setisProcessing(false);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault()
    setisProcessing(true);
    try {
      const { data } = await updateOne("/news/" + value._id, {
        title: value.title,
        description: description,
        image: image,
        cover: cover,
        category: value.category,
        status: value.status,
        tags: value.tags,
        uploadedBy: user.userId
      });

      if (data.success) {
        setValue({
          title: "",
          description: "",
          category: "",
          tags: "",
          status: "",
        })
        setImage([])
        setCover([])
        setDescription('')
        toast.success("User Information Updated");
        close(true)
      }

    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response.data.error || "Error updating user information"
      );
    } finally {
      setisProcessing(false);
    }
  };


  const handleDeleteUser = async () => {
    try {
      var res = await deleteOne("/news/" + value._id);
      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };



  const [eye, setEye] = useState(false)
  const passwordHandler = () => {
    setEye(!eye)
  }

  const [description, setDescription] = useState("")

  const descriptionHandler = (item) => {
    setDescription(item)
  }


  const handleBlock = async () => {
    try {
      const res = await updateOne("/news/block/" + value._id);

      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };

  const handleunBlock = async () => {
    try {
      const res = await updateOne("/news/un-block/" + value._id);
      if (res) {
        toast.success(res.data.msg);
        close(true)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg || "Error. Try again");
    }
  };


  console.log("image")
  console.log(image)
  console.log(cover)
  switch (popupType) {


    case 'add':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {
              isProcessing ? <div className="loader"></div>

                :
                <div className="edit_box">

                  <form onSubmit={addUser} autoComplete="off">
                    <div className="input_items">
                      <div className="input_item">
                        <label htmlFor="">Title</label>
                        <input placeholder="Enter title" onChange={onChange} type="text" name="title" value={value.title} autoComplete="off" autoSave="off" />
                      </div>
                      <div className="input_item">
                        <label htmlFor="">Description</label>
                        <Editor onChange={descriptionHandler} value={description} />
                      </div>


                      <div className="input_item">
                        <label htmlFor="">Thumbnail Image</label>
                        <div className="video_input">
                          <div {...getRootThubProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                            <input id="thubnail" {...getInputThubProps()} />
                            {
                              image.length > 0 ? (
                                // Display image preview
                                <img
                                  src={imgUrl(image[0])}
                                  alt="Preview"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: 200,
                                  }}
                                />
                              )
                                : (<>
                                  <div className="upload_input">
                                    <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                    <span>Uploads </span>
                                  </div>

                                </>)}
                          </div>
                        </div>
                      </div>



                      <div className="input_item">
                        <label htmlFor="">Cover Image</label>
                        <div className="video_input">
                          <div {...getRootCoverProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                            <input id="thubnail" {...getInputCoverProps()} />
                            {
                              cover.length > 0 ? (
                                // Display image preview
                                <img
                                  src={imgUrl(cover[0])}
                                  alt="Preview"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: 200,
                                  }}
                                />
                              )
                                : (<>
                                  <div className="upload_input">
                                    <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                    <span>Uploads </span>
                                  </div>

                                </>)}
                          </div>
                        </div>
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Tags (Use comma for multiple)</label>
                        <input placeholder="Enter tags" onChange={onChange} type="text" name="tags" value={value.tags} autoComplete="off" autoSave="off" />
                      </div>


                      <div className="input_item">
                        <label htmlFor="">Category</label>
                        <select name="category"
                          autoComplete="off"
                          autoSave="off"
                          onChange={onChange}
                          placeholder="Select category">
                          <option value="">Select Category</option>
                          {cat.map(item => (
                            <option selected={value.category === item} value={item}>{item}</option>
                          ))}

                        </select>
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Status</label>
                        <select name="status"
                          autoComplete="off" autoSave="off"
                          onChange={onChange}
                          placeholder="Select status">
                          <option selected={value.status === 'Published'} value={"Published"}>{"Published"}</option>
                          <option selected={value.status === 'Draft'} value={"Draft"}>{"Draft"}</option>
                        </select>
                      </div>


                    </div>

                    <div className="remove_box">
                      <div className="button_item">
                        <button type="submit" className="delete gradient_button">Add</button>
                        <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
            }

          </div>
        </div>



      )
    case 'edit':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>

            {
              isProcessing ? <div className="loader"></div>

                :
                <div className="edit_box">

                  <form onSubmit={updateUser} autoComplete="off">
                    <div className="input_items">
                      <div className="input_item">
                        <label htmlFor="">Title</label>
                        <input placeholder="Enter title" onChange={onChange} type="text" name="title" value={value.title} autoComplete="off" autoSave="off" />
                      </div>
                      <div className="input_item">
                        <label htmlFor="">Description</label>
                        <Editor onChange={descriptionHandler} value={description} />
                      </div>


                      <div className="input_item">
                        <label htmlFor="">Thumbnail Image</label>
                        <div className="video_input">
                          <div {...getRootThubProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                            <input id="thubnail" {...getInputThubProps()} />
                            {
                              image.length > 0 ? (
                                // Display image preview
                                <img
                                  src={imgUrl(image[0])}
                                  alt="Preview"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: 200,
                                  }}
                                />
                              )
                                : (<>
                                  <div className="upload_input">
                                    <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                    <span>Uploads </span>
                                  </div>

                                </>)}
                          </div>
                        </div>
                      </div>



                      <div className="input_item">
                        <label htmlFor="">Cover Image</label>
                        <div className="video_input">
                          <div {...getRootCoverProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                            <input id="thubnail" {...getInputCoverProps()} />
                            {
                              cover.length > 0 ? (
                                // Display image preview
                                <img
                                  src={imgUrl(cover[0])}
                                  alt="Preview"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: 200,
                                  }}
                                />
                              )
                                : (<>
                                  <div className="upload_input">
                                    <Icon icon="material-symbols:file-upload" width="24" height="24" />
                                    <span>Uploads </span>
                                  </div>

                                </>)}
                          </div>
                        </div>
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Tags (Use comma for multiple)</label>
                        <input placeholder="Enter tags" onChange={onChange} type="text" name="tags" value={value.tags} autoComplete="off" autoSave="off" />
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Category</label>
                        <select name="category" autoComplete="off" autoSave="off" onChange={onChange}
                          placeholder="Select category">
                          <option value="">Select Category</option>
                          {cat.map(item => (
                            <option selected={value.category === item} value={item}>{item}</option>
                          ))}

                        </select>
                      </div>

                      <div className="input_item">
                        <label htmlFor="">Status</label>
                        <select name="status"
                          autoComplete="off" autoSave="off"
                          onChange={onChange}
                          placeholder="Select status">
                          <option selected={value.status === 'Published'} value={"Published"}>{"Published"}</option>
                          <option selected={value.status === 'Draft'} value={"Draft"}>{"Draft"}</option>
                        </select>
                      </div>
                    </div>

                    <div className="remove_box">
                      <div className="button_item">
                        <button type="submit" className="delete gradient_button">Update</button>
                        <button type="button" className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>

            }

          </div>
        </div>



      )

    // case 'view':
    //   return (

    //     <div className="popup_wrapper" style={{
    //       display: `${isPopup ? 'block' : 'none'}`
    //     }} onClick={clickOutside}>
    //       <div className="game_review_slider global_scroll" ref={ref}>
    //         <div className="view_admin_news">

    //           <div className="admin_news_data">
    //             <h1>{item.title}</h1>
    //             <img className="image mb16" src={item.image} alt="" />
    //             <img className="cover mb16" src={item.cover} alt="" />
    //             <div className="description mb16">
    //               {ReactHtmlParser(item.description)}
    //             </div>
    //             <p className="mb16">Category: {item.category} </p>
    //             <p className="mb16">Status: {item.status} </p>
    //           </div>


    //           <div className="remove_box mt16">
    //             <div className="button_item">
    //               <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
    //             </div>
    //           </div>

    //         </div>
    //       </div>
    //     </div>
    //   )

    case 'delete':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to delete it?</h2>
              <div className="button_item">
                <button className="delete gradient_button" onClick={handleDeleteUser}>Delete</button>
                <button className="cancel gradient_button" onClick={() => close(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
    case 'block':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to block this news?</h2>
              <div className="button_item">
                <button onClick={handleBlock} className="delete gradient_button">Block</button>
                <button onClick={() => close(false)} className="cancel gradient_button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
    case 'unblock':
      return (
        <div className="popup_wrapper" style={{
          display: `${isPopup ? 'block' : 'none'}`
        }} onClick={clickOutside}>
          <div className="game_review_slider global_scroll" ref={ref}>
            <div className="remove_box">
              <h2>Do you want to unblock this news?</h2>
              <div className="button_item">
                <button onClick={handleunBlock} className="delete gradient_button">Unblock</button>
                <button onClick={() => close(false)} className="cancel gradient_button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )

  }

}

export default ReviewPopup
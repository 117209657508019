import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import UserContext from "./UserContext";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ type }) => {
  const [user, setUser] = useContext(UserContext);
  const token = localStorage.getItem("playercritic_user_Token");
  if (token) {
    // Decode the token to get the user data
    const decoded = jwtDecode(token);

    // Check if the token is expired
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime || (type && user.type !== "Admin")) {
      localStorage.removeItem("playercritic_user_Token");
      alert("Your Token is expired login again");
      setUser("");
      return <Navigate to="/login" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;

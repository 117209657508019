import Select from 'react-select';
import { useState, useEffect } from 'react';
const MultiSelect = ({ type, label, options, onChange, value, placeholder }) => {


  const [opt, setopt] = useState([])
  useEffect(() => {
    if (type === 'brand') {
      const newOptions = options.map(item => {
        return { label: item.title, value: item.title, image: item.image }
      })
      setopt(newOptions)
    } else {
      const newOptions = options.map(item => {
        return { label: item, value: item }
      })
      setopt(newOptions)
    }


  }, [options])

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const multiCategory = (e) => {
    onChange(e, type)
    setIsMenuOpen(false)
  }

  // const optionsVerify = (type) => {
  //   return options.filter(item => item.value !== 'Bulk Business')
  // }


  return (
    <>
      <label htmlFor="">{label}</label>
      <Select
        aria-labelledby="multiselect"
        placeholder={placeholder}
        name="multiselect"
        className="multiselect"
        options={opt}
        closeMenuOnSelect={false}
        // components={animatedComponents}
        defaultValue={value}
        isMulti
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={isMenuOpen}
        onChange={multiCategory}
        value={value}
      />
    </>
  )
}

export default MultiSelect
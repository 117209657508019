import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import { Icon } from '@iconify/react';
import { screen } from "../helpers/functions";
import { useState } from "react";
import { getAll } from "../api";
import { toast } from "react-toastify";
export default function Contact({ }) {

  const [value, setValue] = useState({})

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const [success, setSuccess] = useState(false)
  const [loader, setLoader] = useState(false)
  const submitHandler = (e) => {
    e.preventDefault()
    if (value.subject && value.email) {
      setLoader(true)
      getAll('/users/contact', value).
        then(({ data }) => {
          if (data.success) {
            setSuccess(true)
            setLoader(false)
          }
        })
    } else {
      toast("Subject and Email field can not be empty")
    }

  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="row contact_row">
          <div className="col contact_col_left">



            <form onSubmit={submitHandler}>
              <h1 className="mb16">Contact Us</h1>

              {loader && <div className="loader"></div>}

              {success &&
                <div className="alert alert-success" >
                  Thank you! Your message has been received. We'll be in touch shortly.
                </div>
              }
              <div className="contact_form_wrapper" style={{
                display: (success || loader) ? 'none' : 'block'
              }}>
                <div className="input_item">
                  <label htmlFor="">Subject</label>
                  <Icon icon="material-symbols:subject" width="24" height="24" />
                  <input type="text" name="subject" id=""
                    placeholder="Enter your subject" onChange={changeHandler} required />
                </div>
                <div className="input_item">
                  <label htmlFor="">Name</label>
                  <Icon icon="icon-park-solid:edit-name" width="24" height="24" />
                  <input type="text" name="name" id=""
                    placeholder="Enter your name" onChange={changeHandler} />
                </div>
                <div className="input_item">
                  <label htmlFor="">Email</label>
                  <Icon icon="ic:baseline-email" width="24" height="24" />
                  <input type="text" name="email" id=""
                    placeholder="Enter your email" onChange={changeHandler} required />
                </div>
                <div className="input_item">
                  <label htmlFor="">Phone</label>
                  <Icon icon="ic:baseline-phone" width="24" height="24" />
                  <input type="text" name="phone" id=""
                    placeholder="Enter your phone" onChange={changeHandler} />
                </div>
                <div className="input_item">
                  <label htmlFor="">Message</label>
                  <Icon icon="ic:baseline-message" width="24" height="24" />
                  <textarea name="message" id=""
                    placeholder="Enter your mesage" onChange={changeHandler} />
                </div>
                <div className="input_item">
                  <button type="submit"><Icon icon="material-symbols:send" width="24" height="24" /> Submit</button>
                </div>

              </div>
            </form>

          </div>


          {screen() > 767 &&
            <div className="col contact_col_right">
              <img src="/contact.png" alt="" />
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  );
}

import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { getOne } from "../api";

const UserContext = createContext();

export const handleLogout = (setUser, navigate) => {
  setUser({})
  localStorage.removeItem("playercritic_user_Token");
  navigate("/login");
};


export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({

  });
  const [isCheckingUser, setIsCheckingUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // const storedToken = localStorage.getItem("playercritic_user_Token");

    getOne("/users/auth")
      .then(({ data }) => {

        if (data.success) {
          setUser({
            userId: data.user._id,
            email: data.user.email,
            username: data.user.username,
            image: data.user.image,
            type: data.user.type,
            fname: data.user.fname,
            lname: data.user.fname,
            description: data.user.description,
            address: data.user.address,

          })
          setIsCheckingUser(true)
        } else {
          // localStorage.removeItem("playercritic_user_Token")
          setIsCheckingUser(true)
        }
      })




    // const checkUser = async () => {
    //   const storedToken = localStorage.getItem("playercritic_user_Token");
    //   if (storedToken) {
    //     try {
    //       const decoded = jwtDecode(storedToken);
    //       const currentTime = Date.now() / 1000;
    //       if (decoded.exp < currentTime) {
    //         handleLogout(setUser, navigate);
    //       } else {
    //         setUser({
    //           userId: decoded.user.id,
    //           username: decoded.user.name,
    //           profile: decoded.user.profile,
    //           type: decoded.user.type,

    //         });
    //       }
    //     } catch (error) {
    //       // Handle token decoding errors
    //       console.error("Invalid token:", error);
    //       handleLogout(setUser, navigate);
    //     }
    //   }
    //   setIsCheckingUser(false);
    // };
    // checkUser();
  }, []);


  return (
    <UserContext.Provider value={[user, setUser]}>
      {isCheckingUser && children}
    </UserContext.Provider>
  );
};

export default UserContext;

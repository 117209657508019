import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import SunEditor, { } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './sun.css';

import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,

} from "suneditor/src/plugins";
const Editor = ({ onChange, value }) => {
  const [dataValue, setDataValue] = useState('')
  useEffect(() => {
    setDataValue(value)
  }, [value])

  // const changeHandler = (e) => {
  //   onChange(e)
  //   setDataValue(e)
  // }

  return (<>
    <SunEditor
      setContents={dataValue}
      onChange={onChange}
      // onImageUpload={imgHandler}
      setAllPlugins={true}
      autoFocus={false}
      lang="en"
      setOptions={{
        showPathLabel: false,
        minHeight: "50vh",
        maxHeight: "100%",
        placeholder: "Enter your text here",
        plugins: [
          align,
          font,
          fontColor,
          fontSize,
          formatBlock,
          hiliteColor,
          horizontalRule,
          lineHeight,
          list,
          paragraphStyle,
          table,
          template,
          textStyle,
          image,
          link
        ],

        imageRotation: true,
        imageHeight: '150px',

        // imageWidth: '400px',
        imageUploadSizeLimit: 5000000,
        // imageGalleryHeader: { Authorization: localStorage.getItem("letsmango.com") },
        imageUploadUrl: process.env.REACT_APP_BACKEND + "/api/media/uploads",
        // imageGalleryUrl: url + '/admin/media/api?mode=view&page=0',

        buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
          ],
          ["fontColor", "hiliteColor"],
          ["removeFormat"],
          "/", // Line break
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["table", "link", "image",],

          ['showBlocks', 'codeView'],
        ],
        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        font: [
          "Arial",
          "Calibri",
          "Comic Sans",
          "Courier",
          "Garamond",
          "Georgia",
          "Impact",
          "Lucida Console",
          "Palatino Linotype",
          "Segoe UI",
          "Tahoma",
          "Times New Roman",
          "Trebuchet MS"
        ]
      }}
    />
  </>
  )
}
export default Editor

